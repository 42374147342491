import { API, AccountsURLs } from "../../api"
import ClientBusinessErrorException from "../../exceptions/ClientBusinessErrorException"

const state = {
  dependents: [],
}

const actions = {
  async loadDependents({ commit, rootGetters }) {
    const postBody = {
      tokenzeus: rootGetters["account/tokenzeus"],
      CodOnix: rootGetters["account/onixCode"],
      Cpf: rootGetters["account/loggedUser"].cpf,
    }
    return API.post(AccountsURLs.DEPENDENTS_LIST, postBody)
      .then((response) => {
        if (Array.isArray(response.data.result)) {
          const activeDependents = response.data.result.filter(
            (dep) => dep.DESC_STATUS === "ATIVO"
          )
          commit("updateDependents", activeDependents)
        }
      })
      .catch((err) => {
        switch (err.constructor) {
          case ClientBusinessErrorException:
            commit("updateDependents", [])
            break
          default:
            console.error(err)
            break
        }
      })
      .finally(() => {
        this.loading = false
      })
  },

  // eslint-disable-next-line no-unused-vars
  async deleteDependent({ commit, rootGetters }, cpf){
    const postBody = {
      tokenzeus: rootGetters["account/tokenzeus"],
      CodOnix: rootGetters["account/onixCode"],
      cpf,
    }
    const { data: { user_token } } = await API.post(AccountsURLs.USER_TOKEN, postBody);
    return API.post(AccountsURLs.UPDATE_STATUS, { ...postBody, UserToken: user_token, NovoStatus: 3 });
  }
}

const mutations = {
  updateDependents(state, dependents) {
    state.dependents = dependents
  },
}

const getters = {
  dependents: (state) => state.dependents,
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
