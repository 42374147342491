var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('Header'),(_vm.loggedUser)?_c('div',{staticClass:"text-left px-4 py-2 font-bold"},[_vm._v(" OLÁ "+_vm._s(_vm.userName)+"! ")]):_vm._e(),_vm._m(0),_c('div',{staticClass:"hero pt-2"},[_c('div',{staticClass:"main rounded-t-3xl p-10 pb-8 flex flex-col items-center"},[_c('a',{staticClass:"transition-colors duration-150 border rounded-md focus:shadow-outline banner-item"},[(_vm.codOnix !== '7066')?_c('div',[_c('router-link',{attrs:{"to":{ name: 'appointment.inperson' }}},[_c('div',[_c('img',{attrs:{"src":require("../assets/banner_mobile.png")}})])])],1):_vm._e(),(_vm.codOnix == '7066')?_c('div',[_c('router-link',{attrs:{"to":{ name: 'appointment.video' }}},[_c('div',[_c('img',{attrs:{"src":require("../assets/banner_mobile.png")}})])])],1):_vm._e()]),_c('div',{staticClass:"flex flex-row justify-center"},[(_vm.codOnix !== '7066')?_c('router-link',{attrs:{"to":{ name: 'appointment.inperson' }}},[_c('div',{staticClass:"menu-item m-2 pb-4"},[_c('div',{staticClass:"menu-item-icon"},[_c('img',{attrs:{"src":require("../assets/appointments.jpeg")}})]),_c('p',{staticClass:"text-xs"},[_vm._v("CONSULTAS")])])]):_vm._e(),(
						_vm.codOnix == '5142' ||
						_vm.codOnix == '7067' ||
						_vm.codOnix == '7068' ||
						_vm.codOnix == '7065' ||
						_vm.codOnix == '7112' ||
						_vm.codOnix == '7119' ||
						_vm.codOnix == '7120' ||
						_vm.codOnix == '7136' ||
						_vm.codOnix == '7066'
					)?_c('div',[_c('router-link',{attrs:{"to":{ name: 'appointment.video' }}},[_c('div',{staticClass:"menu-item m-2 pb-3"},[_c('div',{staticClass:"menu-item-icon"},[_c('img',{attrs:{"src":require("../assets/video.jpeg")}})]),_c('p',{staticClass:"text-xs leading-3"},[_vm._v(" CONSULTAS POR VÍDEO ")])])])],1):_vm._e()],1),_c('div',{staticClass:"flex flex-row justify-center"},[(_vm.codOnix == '7068' || _vm.codOnix == '7487')?_c('div',[_c('router-link',{attrs:{"to":{ name: 'appointment.video24h' }}},[_c('div',{staticClass:"menu-item m-2 pb-3"},[_c('div',{staticClass:"menu-item-icon"},[_c('img',{attrs:{"src":require("../assets/video.svg")}})]),_c('p',{staticClass:"text-xs"},[_vm._v(" MÉDICO"),_c('br'),_vm._v(" 24H POR VIDEO ")])])])],1):_vm._e(),(_vm.codOnix == '7068' || _vm.codOnix == '7067')?_c('div',[_c('router-link',{attrs:{"to":{ name: 'surgery' }}},[_c('div',{staticClass:"menu-item m-2 pb-4"},[_c('div',{staticClass:"menu-item-icon"},[_c('img',{attrs:{"src":require("../assets/doctor.png")}})]),_c('p',{staticClass:"text-xs leading-3"},[_vm._v(" PROCEDIMENTOS CIRÚRGICOS ")])])])],1):_vm._e()]),_c('div',{staticClass:"flex flex-row justify-center"},[_c('router-link',{attrs:{"to":{ name: 'appointment.exams' }}},[_c('div',{staticClass:"menu-item m-2 pb-4"},[_c('div',{staticClass:"menu-item-icon"},[_c('img',{attrs:{"src":require("../assets/exams.jpeg")}})]),_c('p',{staticClass:"text-xs leading-3"},[_vm._v("EXAMES E VACINAS")])])]),_c('router-link',{attrs:{"to":{ name: 'schedule' }}},[_c('div',{staticClass:"menu-item m-2 pb-4"},[_c('div',{staticClass:"menu-item-icon"},[_c('img',{attrs:{"src":require("../assets/schedule.jpeg")}})]),_c('p',{staticClass:"text-xs leading-3"},[_vm._v("MEUS AGENDAMENTOS")])])])],1),_c('div',{staticClass:"flex flex-row justify-center"},[(
						_vm.codOnix == '5142' ||
						_vm.codOnix == '7067' ||
						_vm.codOnix == '7068' ||
						_vm.codOnix == '7065' ||
						_vm.codOnix == '7112' ||
						_vm.codOnix == '7119' ||
						_vm.codOnix == '7120' ||
						_vm.codOnix == '7136' ||
						_vm.codOnix == '7066' ||
						_vm.codOnix == '9004'
					)?_c('div',[_vm._m(1)]):_vm._e()]),_vm._m(2),_c('div',{staticClass:"flex flex-row justify-center"},[_c('router-link',{attrs:{"to":{ name: 'drugstores' }}},[_c('div',{staticClass:"menu-item m-2 pb-4"},[_c('div',{staticClass:"menu-item-icon"},[_c('img',{attrs:{"src":require("../assets/drugstores.jpeg")}})]),_c('p',{staticClass:"text-xs"},[_vm._v("FARMÁCIAS")])])])],1)])]),_c('div',{staticClass:"main pt-2"},[_c('div',{staticClass:"bg-avocado rounded-t-3xl p-16 pt-10 flex flex-col items-center"},[_c('p',{staticClass:"font-bold text-center text-gray-900 text-xl mb-6"},[_vm._v(" Administre sua conta PagBank Saúde: ")]),_c('div',{staticClass:"flex flex-row justify-center"},[(
						_vm.codOnix == '5142' ||
						_vm.codOnix == '7065' ||
						_vm.codOnix == '7066' ||
						_vm.codOnix == '7067' ||
						_vm.codOnix == '7068' ||
						_vm.codOnix == '7112' ||
						_vm.codOnix == '7119' ||
						_vm.codOnix == '7120' ||
						_vm.codOnix == '7136'
					)?_c('div',[_c('router-link',{attrs:{"to":{ name: 'credit' }}},[_c('div',{staticClass:"menu-item m-2 pb-4"},[_c('div',{staticClass:"menu-item-icon"},[_c('img',{attrs:{"src":require("../assets/money.svg")}})]),_c('p',{staticClass:"text-xs"},[_vm._v(" CARREGAR MEU PAGBANK SAÚDE ")])])])],1):_vm._e(),_c('router-link',{attrs:{"to":{ name: 'account.statement' }}},[_c('div',{staticClass:"menu-item m-2 pb-4"},[_c('div',{staticClass:"menu-item-icon"},[_c('img',{attrs:{"src":require("../assets/statement.jpeg")}})]),_c('p',{staticClass:"text-xs"},[_vm._v("EXTRATO")])])])],1),_c('div',{staticClass:"flex flex-row justify-center"},[_c('router-link',{attrs:{"to":{ name: 'account.mycard' }}},[_c('div',{staticClass:"menu-item m-2 pb-3"},[_c('div',{staticClass:"menu-item-icon"},[_c('img',{attrs:{"src":require("../assets/card.jpeg")}})]),_c('p',{staticClass:"text-xs leading-3"},[_vm._v(" MEU CARTÃO PAGBANK SAÚDE ")])])]),_c('router-link',{attrs:{"to":{ name: 'account.dependents' }}},[_c('div',{staticClass:"menu-item m-2 pb-4"},[_c('div',{staticClass:"menu-item-icon"},[_c('img',{attrs:{"src":require("../assets/dependents.jpeg")}})]),_c('p',{staticClass:"text-xs leading-3"},[_vm._v("DEPENDENTES")])])])],1),_c('div',{staticClass:"flex flex-row justify-center"},[_c('router-link',{attrs:{"to":{ name: 'account.venues' }}},[_c('div',{staticClass:"menu-item m-2 pb-3"},[_c('div',{staticClass:"menu-item-icon"},[_c('img',{attrs:{"src":require("../assets/search.jpeg")}})]),_c('p',{staticClass:"text-xs"},[_vm._v("BUSCAR REDE CREDENCIADA")])])]),(
						_vm.codOnix == '5142' ||
						_vm.codOnix == '7065' ||
						_vm.codOnix == '7066' ||
						_vm.codOnix == '7067' ||
						_vm.codOnix == '7068' ||
						_vm.codOnix == '7112' ||
						_vm.codOnix == '7119' ||
						_vm.codOnix == '7120' ||
						_vm.codOnix == '7136'
					)?_c('div',[_c('router-link',{attrs:{"to":{
							name: 'account.profile.edit',
							params: { user: _vm.loggedUser },
						}}},[_c('div',{staticClass:"menu-item m-2 pb-3"},[_c('div',{staticClass:"menu-item-icon"},[_c('img',{attrs:{"src":require("../assets/profile.jpeg")}})]),_c('p',{staticClass:"text-xs"},[_vm._v("ALTERAR DADOS")])])])],1):_vm._e()],1)])]),_c('transition',{attrs:{"name":"fade"}},[(_vm.show)?_c('div',{staticClass:"modal"},[_c('div',{staticClass:"modal__backdrop"},[_c('div',{staticClass:"modal__dialog"},[_c('div',{staticClass:"modal__body"},[_vm._t("body"),_c('div',{staticClass:"py-1"},[_c('center',[_c('div',[_c('br')]),_c('div',{staticClass:"update_date"},[_c('h1',[_c('b',[_vm._v("ATUALIZE SEU DADOS")])])]),_c('div',[_c('br')]),_c('div',[_c('p',[_vm._v(" É necessário atualizar seus"),_c('br'),_vm._v(" dados para continuar. ")])]),_c('div',[_c('br')]),_c('button',{staticClass:"btn btn-blue btn-lg mb-12",on:{"click":_vm.data_update}},[_vm._v(" ATUALIZAR ")])])],1)],2)])])]):_vm._e()]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col items-center py-6 px-20 hero"},[_c('div',{staticClass:"mb-2"},[_c('img',{attrs:{"src":require("../assets/pagbank-hero.png"),"alt":"Hero"}})]),_c('p',{staticClass:"font-bold text-center text-gray-900 text-xl"},[_vm._v(" Solicite aqui suas consultas ou exames e acompanhe seus agendamentos: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://meutem.com.br/pagbanksaude/"}},[_c('div',{staticClass:"menu-item m-2 pb-3"},[_c('div',{staticClass:"menu-item-icon"},[_c('img',{attrs:{"src":require("../assets/saiba_mais.png")}})]),_c('p',{staticClass:"text-xs leading-3"},[_vm._v("SAIBA MAIS")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-6"},[_c('h1',{staticClass:"font-bold text-xl"},[_vm._v(" Encontre farmácias e economize: ")])])
}]

export { render, staticRenderFns }