<template>
	<div class="main">
		<Header />
		<div v-if="loggedUser" class="text-left px-4 py-2 font-bold">
			OLÁ {{ userName }}!
		</div>
		<div class="flex flex-col items-center py-6 px-20 hero">
			<div class="mb-2">
				<img src="../assets/pagbank-hero.png" alt="Hero" />
			</div>
			<p class="font-bold text-center text-gray-900 text-xl">
				Solicite aqui suas consultas ou exames e acompanhe seus
				agendamentos:
			</p>
		</div>
		<div class="hero pt-2">
			<div
				class="main rounded-t-3xl p-10 pb-8 flex flex-col items-center"
			>
				<a
					class="transition-colors duration-150 border rounded-md focus:shadow-outline banner-item"
				>
					<div v-if="codOnix !== '7066'">
						<router-link :to="{ name: 'appointment.inperson' }">
							<div>
								<img src="../assets/banner_mobile.png" />
							</div>
						</router-link>
					</div>

					<div v-if="codOnix == '7066'">
						<router-link :to="{ name: 'appointment.video' }">
							<div>
								<img src="../assets/banner_mobile.png" />
							</div>
						</router-link>
					</div>
				</a>
				<div class="flex flex-row justify-center">
					<router-link
						:to="{ name: 'appointment.inperson' }"
						v-if="codOnix !== '7066'"
					>
						<div class="menu-item m-2 pb-4">
							<div class="menu-item-icon">
								<img src="../assets/appointments.jpeg" />
							</div>
							<p class="text-xs">CONSULTAS</p>
						</div>
					</router-link>

					<div
						v-if="
							codOnix == '5142' ||
							codOnix == '7067' ||
							codOnix == '7068' ||
							codOnix == '7065' ||
							codOnix == '7112' ||
							codOnix == '7119' ||
							codOnix == '7120' ||
							codOnix == '7136' ||
							codOnix == '7066'
						"
					>
						<router-link :to="{ name: 'appointment.video' }">
							<div class="menu-item m-2 pb-3">
								<div class="menu-item-icon">
									<img src="../assets/video.jpeg" />
								</div>
								<p class="text-xs leading-3">
									CONSULTAS POR VÍDEO
								</p>
							</div>
						</router-link>
					</div>
				</div>

				<div class="flex flex-row justify-center">
					<div v-if="codOnix == '7068' || codOnix == '7487'">
						<router-link :to="{ name: 'appointment.video24h' }">
							<div class="menu-item m-2 pb-3">
								<div class="menu-item-icon">
									<img src="../assets/video.svg" />
								</div>
								<p class="text-xs">
									MÉDICO<br />
									24H POR VIDEO
								</p>
							</div>
						</router-link>
					</div>

					<div v-if="codOnix == '7068' || codOnix == '7067'">
						<router-link :to="{ name: 'surgery' }">
							<div class="menu-item m-2 pb-4">
								<div class="menu-item-icon">
									<img src="../assets/doctor.png" />
								</div>
								<p class="text-xs leading-3">
									PROCEDIMENTOS CIRÚRGICOS
								</p>
							</div>
						</router-link>
					</div>
				</div>

				<div class="flex flex-row justify-center">
					<router-link :to="{ name: 'appointment.exams' }">
						<div class="menu-item m-2 pb-4">
							<div class="menu-item-icon">
								<img src="../assets/exams.jpeg" />
							</div>
							<p class="text-xs leading-3">EXAMES E VACINAS</p>
						</div>
					</router-link>

					<router-link :to="{ name: 'schedule' }">
						<div class="menu-item m-2 pb-4">
							<div class="menu-item-icon">
								<img src="../assets/schedule.jpeg" />
							</div>
							<p class="text-xs leading-3">MEUS AGENDAMENTOS</p>
						</div>
					</router-link>
				</div>
				<div class="flex flex-row justify-center">
					<div
						v-if="
							codOnix == '5142' ||
							codOnix == '7067' ||
							codOnix == '7068' ||
							codOnix == '7065' ||
							codOnix == '7112' ||
							codOnix == '7119' ||
							codOnix == '7120' ||
							codOnix == '7136' ||
							codOnix == '7066' ||
							codOnix == '9004'
						"
					>
						<a href="https://meutem.com.br/pagbanksaude/">
							<div class="menu-item m-2 pb-3">
								<div class="menu-item-icon">
									<img src="../assets/saiba_mais.png" />
								</div>
								<p class="text-xs leading-3">SAIBA MAIS</p>
							</div>
						</a>
					</div>
				</div>

				<div class="my-6">
					<h1 class="font-bold text-xl">
						Encontre farmácias e economize:
					</h1>
				</div>

				<div class="flex flex-row justify-center">
					<router-link :to="{ name: 'drugstores' }">
						<div class="menu-item m-2 pb-4">
							<div class="menu-item-icon">
								<img src="../assets/drugstores.jpeg" />
							</div>
							<p class="text-xs">FARMÁCIAS</p>
						</div>
					</router-link>
				</div>
			</div>
		</div>

		<div class="main pt-2">
			<div
				class="bg-avocado rounded-t-3xl p-16 pt-10 flex flex-col items-center"
			>
				<p class="font-bold text-center text-gray-900 text-xl mb-6">
					Administre sua conta PagBank Saúde:
				</p>

				<div class="flex flex-row justify-center">
					<div
						v-if="
							codOnix == '5142' ||
							codOnix == '7065' ||
							codOnix == '7066' ||
							codOnix == '7067' ||
							codOnix == '7068' ||
							codOnix == '7112' ||
							codOnix == '7119' ||
							codOnix == '7120' ||
							codOnix == '7136'
						"
					>
						<router-link :to="{ name: 'credit' }">
							<div class="menu-item m-2 pb-4">
								<div class="menu-item-icon">
									<img src="../assets/money.svg" />
								</div>
								<p class="text-xs">
									CARREGAR MEU PAGBANK SAÚDE
								</p>
							</div>
						</router-link>
					</div>

					<router-link :to="{ name: 'account.statement' }">
						<div class="menu-item m-2 pb-4">
							<div class="menu-item-icon">
								<img src="../assets/statement.jpeg" />
							</div>
							<p class="text-xs">EXTRATO</p>
						</div>
					</router-link>
				</div>

				<div class="flex flex-row justify-center">
					<router-link :to="{ name: 'account.mycard' }">
						<div class="menu-item m-2 pb-3">
							<div class="menu-item-icon">
								<img src="../assets/card.jpeg" />
							</div>
							<p class="text-xs leading-3">
								MEU CARTÃO PAGBANK SAÚDE
							</p>
						</div>
					</router-link>

					<router-link :to="{ name: 'account.dependents' }">
						<div class="menu-item m-2 pb-4">
							<div class="menu-item-icon">
								<img src="../assets/dependents.jpeg" />
							</div>
							<p class="text-xs leading-3">DEPENDENTES</p>
						</div>
					</router-link>
				</div>

				<div class="flex flex-row justify-center">
					<router-link :to="{ name: 'account.venues' }">
						<div class="menu-item m-2 pb-3">
							<div class="menu-item-icon">
								<img src="../assets/search.jpeg" />
							</div>
							<p class="text-xs">BUSCAR REDE CREDENCIADA</p>
						</div>
					</router-link>

					<div
						v-if="
							codOnix == '5142' ||
							codOnix == '7065' ||
							codOnix == '7066' ||
							codOnix == '7067' ||
							codOnix == '7068' ||
							codOnix == '7112' ||
							codOnix == '7119' ||
							codOnix == '7120' ||
							codOnix == '7136'
						"
					>
						<router-link
							:to="{
								name: 'account.profile.edit',
								params: { user: loggedUser },
							}"
						>
							<div class="menu-item m-2 pb-3">
								<div class="menu-item-icon">
									<img src="../assets/profile.jpeg" />
								</div>
								<p class="text-xs">ALTERAR DADOS</p>
							</div>
						</router-link>
					</div>
				</div>
			</div>
		</div>

		<transition name="fade">
			<div class="modal" v-if="show">
				<div class="modal__backdrop">
					<div class="modal__dialog">
						<div class="modal__body">
							<slot name="body" />
							<div class="py-1">
								<center>
									<div><br /></div>
									<div class="update_date">
										<h1><b>ATUALIZE SEU DADOS</b></h1>
									</div>
									<div><br /></div>
									<div>
										<p>
											É necessário atualizar seus<br />
											dados para continuar.
										</p>
									</div>
									<div><br /></div>
									<button
										class="btn btn-blue btn-lg mb-12"
										@click="data_update"
									>
										ATUALIZAR
									</button>
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>

		<Footer />
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "../components/app/Header";
import Footer from "../components/app/Footer";
import { API, AccountsURLs } from "../api";
import { datesMixin } from "../mixins/dates";

export default {
	components: { Header, Footer },
	computed: {
		...mapGetters("account", ["loggedUser"]),

		userName() {
			return this.loggedUser.name;
		},
		codOnix() {
			if (!this.loggedUser) {
				return null;
			}

			return this.loggedUser.onixCode;
		},
	},

	mixins: [datesMixin],

	name: "Modal",
	data() {
		const user = this.$store.getters["account/loggedUser"];
		return {
			show: false,
			loading: false,
			name: user.name,
			cpf: user.cpf,
			birthDate: this.convertDate(user.birthDate, "DD/MM/YYYY"),
			phone: user.phone,
			email: user.email,
		};
	},

	mounted() {
		const user = this.$store.getters["account/loggedUser"];
		if (
			user.onixCode == "7065" ||
			user.onixCode == "7136" ||
			user.onixCode == "7112" ||
			user.onixCode == "7119" ||
			user.onixCode == "7120"
		) {
			this.firstAccess();
		}
	},

	methods: {
		firstAccess() {
			const options = {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Transaction-Key":
						"njRRmvsxpZyUbU2fUsdbppEaMeNkLLARkNQg44Wks7rbscHNmL",
				},
				body: JSON.stringify({
					cpf: this.loggedUser.cpf,
					channel_id: 147,
				}),
			};
			fetch("https://api.meutem.com.br/clients/getFirstAccess", options)
				.then((response) => response.json())
				.then((data) => {
					if (data == 1) {
						this.show = true;
						document
							.querySelector("body")
							.classList.add("overflow-hidden");
					}
				})
				.catch((error) => {
					console.log("error first access: " + error);
				});
		},

		closeModal() {
			this.show = false;
			document.querySelector("body").classList.remove("overflow-hidden");
		},

		openModal() {
			this.show = true;
			document.querySelector("body").classList.add("overflow-hidden");
		},

		data_update() {
			window.location.href = "/account/profile/edit";
		},

		save() {
			this.loading = true;

			const cnpjPagSeguro = "08561701000101";
			const loggedUser = this.$store.getters["account/loggedUser"];
			const data = {
				tokenzeus: this.$store.getters["account/tokenzeus"],
				data_nascimento: this.rawDate(this.birthDate),
				Telefone: this.phone,
				cpf: this.loggedUser.cpf,
				cnpj: cnpjPagSeguro,
				Nome: this.name,
				CodOnix: this.$store.getters["account/onixCode"],
			};

			API.post(AccountsURLs.UPDATE_ADHESION, data)
				.then(({ data: { clients } }) => {
					this.$notify(
						{
							group: "success",
							title: "Sucesso",
							text: this.successText,
						},
						3000
					);

					this.$store.commit("account/setLogged", {
						tokenzeus: this.$store.getters["account/tokenzeus"],
						loggedUser: {
							...loggedUser,
							birthDate: clients[0].birthdate,
							phone: clients[0].telephone_1,
						},
					});
				})
				.catch((err) => {
					this.$toasted.show(err.message, {
						position: "bottom-center",
						type: "error",
						duration: 3500,
						fullWidth: true,
					});
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/form.scss";
</style>
