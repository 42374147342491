<template>
    <Page :back-to="backTo" :loading="loading">
        <div class="app-container">
            <h1 class="navigation-title my-5">DEPENDENTES</h1>

            <div class="mb-16"><Input name="name" label="Nome:" placeholder="Nome" v-model="name" :disabled="!this.dependent && !canInsert"/></div>

            <div :disabled="this.dependent" class="mb-16"><Input name="cpf"  @input="handleCpfChange" label="CPF:" v-mask="'###.###.###-##'" placeholder="000.000.000-00" v-model="cpf" :disabled="this.dependent || !canInsert ? 'disabled' : false"/></div>

            <div :disabled="this.dependent" class="mb-16"><Input name="birthDate" @input="handleBirthdateChange" label="Data Nascimento:" v-mask="'##/##/####'" placeholder="00/00/0000" v-model="birthDate" /></div>

            <button class="btn btn-blue btn-lg mb-12" @click="save" :disabled="isDisabled()">SALVAR</button>
        </div>
    </Page>
</template>

<script>
import Page from '../../../components/app/Page'
import Input from '../../../components/ui/form/Input'
import { API, AccountsURLs } from '../../../api'
import ServerErrorException from '../../../exceptions/ServerErrorException'
import ClientErrorException from '../../../exceptions/ClientErrorException'
import { datesMixin } from '../../../mixins/dates'
import formatCpf from '../../../utils/formatCpf'
import { validate } from 'gerador-validador-cpf'
import { isValid } from 'date-fns'

export default {
    components: { Page, Input },

    mixins: [ datesMixin ],

    props: [ 'backTo', 'dependent' ],

    data() {
        return {
            canInsert: true,
            loading: false,
            name: this.dependent ? this.dependent.NOME_DEPENDENTE : '',
            cpf: this.dependent ? this.dependent.CPF_DEPENDENTE : '',
            birthDate: this.dependent ? `${this.dependent.DATA_NASCIMENTO.split('-')[2]}/${this.dependent.DATA_NASCIMENTO.split('-')[1]}/${this.dependent.DATA_NASCIMENTO.split('-')[0]}` : '',
        }
    },

    computed: {
        
        successText() {

            return this.dependent ? "Dependente salvo com sucesso!" : "Dependente adicionado com sucesso!"
        },

    },
    
    mounted(){
        if(this.$store.getters['dependents/dependents'].length >= 4 && !this.dependent){
            this.canInsert = false
            this.$toasted.show('Você alcançou o número máximo de dependentes (4). Não será possível cadastrar um novo dependente.', { position: 'bottom-center', type: 'error', duration: 5000, fullWidth: true,})
        }
    },

    methods: {

        isValidDate(){
            const date = new Date(this.birthDate.split('/')[2], parseInt(this.birthDate.split('/')[1]) - 1, this.birthDate.split('/')[0]);
            if((date.getFullYear() > new Date().getFullYear()) || (!isValid(date))){
                return false
            }

            return true
        },

        handleBirthdateChange(){
            if(this.birthDate.length === 10){
                if(!this.isValidDate()){
                    this.birthDate = ''
                    this.$toasted.show('Data de nascimento inválida.', { position: 'bottom-center', type: 'error', duration: 2500, fullWidth: true,})
                }
            }
        },

        handleCpfChange(){
            if(this.cpf.length === 14 && !validate(this.cpf)){
                this.$toasted.show('CPF digitado não é válido.', { position: 'bottom-center', type: 'error', duration: 2500, fullWidth: true,})
            }
        },

        isDisabled() {
            if(!this.canInsert){
                return true
            }
            if(!this.isValidDate()){
                return true
            }
            if(this.name.length < 4){
                return true
            }
            if(!validate(this.cpf)){
                return true
            } else {
                if(formatCpf(this.cpf) === this.$store.getters['account/loggedUser'].cpf){
                    this.$toasted.show('Não é possível cadastrar o Títular como dependente', { position: 'bottom-center', type: 'error', duration: 2500, fullWidth: true,})
                    return true
                }
            }if(this.birthDate.length < 10){
                return true
            }
            return false
        },

        save() {

            this.loading = true

            const cnpjPagSeguro = "08561701000101"

            const data = {
                "tokenzeus": this.$store.getters['account/tokenzeus'],
                "data_nascimento": this.rawDate(this.birthDate),
                "cpfTitular": this.$store.getters['account/loggedUser'].cpf,
                "cpf": this.cpf,
                "cnpj": cnpjPagSeguro,
                "Nome": this.name,
                "CodOnix": this.$store.getters['account/onixCode']
            }

           API.post(this.dependent ? AccountsURLs.UPDATE_ADHESION : AccountsURLs.DEPENDENTS_ADHESION, data)
            .then(() => {
                this.$notify({
                    group: "success",
                    title: "Sucesso",
                    text: this.successText
                }, 3000);

                this.$router.push(this.backTo)
            })
            .catch(err => {

                switch (err.constructor) {
                    // Case with blocks - https://eslint.org/docs/rules/no-case-declarations
                    case ServerErrorException: {
                        let msg = err.message + ''

                        if(msg.search('TokenZeus') > -1) {
                            this.$router.push({ name: "login" })
                        }
                        break;
                    }
                    case ClientErrorException: {
                        this.$toasted.show(err.message, { position: 'bottom-center', type: 'error', duration: 3500, fullWidth: true,})
                        break
                    }
                    default: {
                        this.$toasted.show('Não foi possível cadastrar o dependente. Tente mais tarde', { position: 'bottom-center', type: 'error', duration: 2500, fullWidth: true,})
                        console.error(err)
                        break;
                    }
                }
            })
            .finally(() => {
                this.loading = false
            })
        }
    }
}
</script>