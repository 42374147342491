<template>
    <Page back-to="home" title="SOLICITAÇÃO PARA AGENDAMENTO DE CONSULTA POR VÍDEO/TELEMEDICINA" :steps="3" :current-step="1" :loading="loading">

        <h4 class="navigation-subtitle">O que você quer agendar?</h4>

        <form class="mt-12" v-on:submit="submitForm">

            <DependentsDropdown name="dependent" label="Para quem é o atendimento:" v-model="dependent" required="true" />
            
            <router-link :to="{ name: 'account.dependents', params: { backTo: 'appointment.video' } }"><div class="mb-16 text-center text-blue underline font-bold">ADICIONAR OU EDITAR DEPENDENTES</div></router-link>                    

            <Dropbox name="speciality" emptyOption="true" label="Selecione a especialidade:" :items="specialities" v-model="speciality" required="true"/>

            <h4 class="font-bold text-gray-500 text-left mb-6 mt-16 text-lg">Para quando você precisa de atendimento?</h4>


            <button class="btn btn-blue btn-lg mb-12" type="button" v-on:click="() => selectAndSubmit('appointment.video.schedule')">
                PRÓXIMO
            </button>
            <button type="submit" class="hidden" ref="btnSubmit"> as </button> 
        </form>
    </Page>
</template>

<script>
//             <button class="btn btn-blue btn-lg mb-12" type="button" v-on:click="() => selectAndSubmit('appointment.video.picktime')">
//                 HOJE
//             </button>

//             <h4 class="navigation-subtitle my-6 text-gray-500">OU</h4>
            
import Page from '../../../components/app/Page'
import Dropbox from '../../../components/ui/form/Dropbox'
import DependentsDropdown from '../../../components/common/DependentsDropdown'

export default {
    components: { Page, DependentsDropdown, Dropbox },

    data() {
        return {
            loading: false,
            routeTo: 'appointment.video.schedule',
            specialities: [
                'Cardiologia',
                'Clínico Geral',
                'Cirurgia geral',
                'Cirurgia Plástica',
                'Dermatologia',
                'Endocrinologia',
                'Gastroenterologia',
                'Geriatria',
                'Ginecologia',
                'Mastologia',
                'Neurologia',
                'Obstetricia',
                'Oftalmologia',
                'Ortopedia',
                'Pediatria',
                'Pneumologia',
                "Psicologia",
                'Psiquiatria',
                'Reumatologia',
                'Urologia'
            ],
        }
    },

    mounted() {

        this.loadDependents()
    },

    methods: {

        loadDependents() {

            this.$store.dispatch('dependents/loadDependents')
        },

        submitForm(e){
            e.preventDefault();
            this.$router.push({ name: this.routeTo  })
        },

        selectAndSubmit(route){
            this.routeTo = route;
            this.$refs.btnSubmit.click()
        },
    },

    computed: {

        dependent: {
            get () {
                return this.$store.state.appointments.personalInfo.dependent
            },
            set (value) {
                this.$store.commit('appointments/updateDependent', value)
            }
        },
        
        speciality: {
            get () {
                return this.$store.getters['appointments/speciality']
            },
            set (value) {
                this.$store.commit('appointments/updateSpeciality', value)
            }
        },
    }
}
</script>