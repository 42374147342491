<template>
    <div class="app-container">

        <div class="w-100 flex justify-center mt-20 mb-10">
            <img class="success" src="../../assets/pagbank-success.png" alt="Sucesso">
        </div>

        <h1 class="navigation-title text-center">RECEBEMOS SUA SOLICITAÇÃO</h1>

        <h1 class="text-gray-500 font-bold mt-10 mb-20 text-lg px-14 text-center">{{ successMessage }}</h1>

        <router-link v-on:click.native="needClear" :to="{ name: 'home' }"><button class="btn btn-blue btn-lg mb-16">ENTENDI</button></router-link>
    </div>
</template>

<script>
export default {
    props: [ 'message' ],

    computed: {

        successMessage() {

            return this.message || 'Em até 3 dias úteis, nossa equipe entrará em contato por Whats e/ou e-mail. Para acompanhar a sua solicitação, você pode entrar em contato através do telefone 4003-1775 para capitais e regiões metropolitanas ou 0800-7282174 para demais regiões.'
        }
    },

    methods: {
        needClear() {
            this.$store.commit('appointments/resetState')
        }
    }
}
</script>

<style lang="scss" scoped>
    img.success {
        max-width: 150px;
        max-height: 150px;
    }
</style>