import { render, staticRenderFns } from "./LoginError.vue?vue&type=template&id=d4b46ce6&scoped=true"
var script = {}
import style0 from "./LoginError.vue?vue&type=style&index=0&id=d4b46ce6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4b46ce6",
  null
  
)

/* custom blocks */
import block0 from "./LoginError.vue?vue&type=custom&index=0&blockType=link&href=https%3A%2F%2Ffonts.googleapis.com%2Fcss2%3Ffamily%3DMontserrat%26display%3Dswap&rel=stylesheet"
if (typeof block0 === 'function') block0(component)

export default component.exports