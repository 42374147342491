<template>
    <Page title="" backTo="home">
        
        <div class="py-6 mb-10">
            
            <div class="card">
                <div class="card-bg"></div>
                <div class="card-content flex flex-col justify-between">
                    <div></div>
                    <div>
                        <div class="font-medium text-sm md:text-base">{{ userName }}</div>
                        <div class="font-bold mb-1 text-sm md:text-base">{{ cardNumber }}</div>
                        <div class="flex mini-items pr-10">
                            <div>Cód. Seg {{ validator }}</div>
                            <div>Emissor {{ emitter }}</div>
                            <div>Produto {{ product }}</div>
                            <div>Via {{ way }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="partners mt-8">
                <img v-if="isEpharmaProductValid" src="../../assets/epharma.png" alt="ePharma">
                <img v-else src="../../assets/vidalink.png" alt="Vidalink">
                <img src="../../assets/tem-saude.png" alt="TEM Saúde">
            </div>

            <transition name="slide-fade">
                <div class="code-sent mt-16 text-gray-500 font-bold text-md text-left" v-if="codeSent">
                    Uma senha foi enviada via SMS para o número cadastrado {{phone.substr(0,4)}} *****-{{phone.substr(11)}}.
                </div>
            </transition>
            <button class="btn btn-blue btn-lg my-8" @click="handleResetPassword">SOLICITAR NOVA SENHA</button>
        </div>
        
    </Page>
</template>

<script>
import Page from '../../components/app/Page'
import { mapGetters } from 'vuex'
import { API, AccountsURLs } from '../../api'

import ClientErrorException from "../../exceptions/ClientErrorException"
import ServerErrorException from "../../exceptions/ServerErrorException"

export default {
    computed: {
        ...mapGetters('account', ['loggedUser', 'tokenzeus', 'epharmaProducts']),

        userName() {
            return this.loggedUser.name
        },

        cardNumber() {
            return this.loggedUser.cardNumber
        },
        
        emitter() {
            return this.loggedUser.emitter
        },

        product() {
            return this.loggedUser.product
        },

        way() {
            return this.loggedUser.way
        },

        validator() {
            return this.loggedUser.validator
        },
        phone() {
            return this.loggedUser.phone
        },

        isEpharmaProductValid() {
            return this.epharmaProducts.includes(String(this.loggedUser.product));
        }
    },

    components: { Page },

    data() {
        return {
            codeSent: false
        }
    },

    methods: {

        handleResetPassword() {

            const context = this;
            context.codeSent = true
            const data = {
                "sendTo": this.loggedUser.phone.replaceAll(/[^\d]/g, ""),
                "lang": 1,
                "event": "CONFIRMATION",
                "partner": "PAGBANK",
                "projectName": "TEM",
                "cardNumber": this.loggedUser.cardNumber,
                "cardPassword": Math.floor(100000 + Math.random() * 900000)
            }


           API.post(AccountsURLs.RESET_PASSWORD, data, { headers: { token: this.tokenzeus }})
            .then(() => {
                this.$notify({
                    group: "success",
                    title: "Sucesso",
                    text: this.successText
                }, 3000);

                this.$router.push(this.backTo)
            })
            .catch(err => {
                
                switch (err.constructor) {
                    // Case with blocks - https://eslint.org/docs/rules/no-case-declarations
                    case ServerErrorException: {
                        let msg = err.message + ''

                        if(msg.search('TokenZeus') > -1) {
                            this.$router.push({ name: "login" })
                        }
                        break;
                    }
                    case ClientErrorException: {
                        this.$toasted.show(err.message, { position: 'bottom-center', type: 'error', duration: 3500, fullWidth: true,})
                        break
                    }
                    default: {
                        this.$toasted.show('Erro interno, tente novamente mais tarde.', { position: 'bottom-center', type: 'error', duration: 2500, fullWidth: true,})
                        console.error(err)
                        console.log(err.response)
                        break;
                    }
                }
            })
            .finally(() => {
                setTimeout(() => (context.codeSent = false), 3000)
                
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.card {
    position: relative;

    .card-bg {

        background-image: url('../../assets/pagbank-card.png');
        background-size: 100%;
        padding-top: 66.93%;
    }

    .card-content {
        
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: left;

        @apply px-6 md:px-10 py-8;
    }

    .mini-items {
        font-size: 0.65rem;
        font-weight: 500;

        div {
            margin-right: 10px;;
        }
    }
}

.partners {
    display: flex;
    justify-content: space-between;

    img {
        max-height: 56px;
    }
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>