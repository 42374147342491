<template>
    <Page title="HISTÓRICO DE UTILIZAÇÃO" backTo="home">
        <div class="border-b"></div>
        
        <div class="py-6 mb-10">
            <div class="statement-card py-1 flex" v-for="(item, index) in extract" :key="index">

                <div class="mr-5 flex flex-col" v-if="item.tipo_transacao_financeiro">
                    <img class="icon" src="../../assets/card.jpeg">
                    <div class="icon-line"></div>
                </div>
                <div class="text-left font-bold pb-8 flex-grow" v-if="item.tipo_transacao_financeiro">
                    <div class="flex justify-between">
                        <div>{{ item.tipo_transacao_financeiro }}</div>
                        <div class="text-gray-500">{{ format(new Date(item.data_transacao), 'd/L') }}</div>
                    </div>
                    <div v-if="item.venue" class="font-bold text-xl mb-0 mt-2">
                        
                    </div>
                    <div class="font-bold text-lg">
                        {{ item.valor_transacao_d.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
                    </div>
                </div>
            </div>
        </div>
        
    </Page>
</template>

<script>
import Page from '../../components/app/Page'
import { mapGetters } from 'vuex'
import { format } from 'date-fns';

export default {
    components: { Page },
    computed: {
        ...mapGetters('account', ['extract']),
    },
    mounted() {
        this.loadExtract()
    },
    methods: {
        format, 
        loadExtract() {

            this.loading = true

            this.$store.dispatch('account/loadExtract')
            .finally(() => {
                this.loading = false
            })
        },
    }

}
</script>

<style lang="scss" scoped>
.icon {
    max-width: 24px;
}

.statement-card:not(:last-child) {

    .icon-line {
    position: relative;
    flex-grow: 1;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 1px;
        height: 100%;
        @apply bg-green;
    }
}
}

.icon-line {
    position: relative;
    flex-grow: 1;

    
}
</style>