<template>
    <Dropbox name="dependent" label="Para quem é o atendimento:" :items="dependents" :value="value" @input="change" :required="required"></Dropbox>
</template>

<script>
import { mapGetters } from 'vuex'
import Dropbox from '../../components/ui/form/Dropbox'

export default {

    components: { Dropbox },

    props: [ 'value', 'required' ],
    
    mounted(){
        this.$emit('input', this.loggedUser.name)
    },

    computed: {

        ...mapGetters('account', ['loggedUser']),

        dependents() {

            var dependents = [ this.loggedUser.name ]
            this.$store.getters['dependents/dependents'].forEach((dependent) => {
                dependents.push(dependent.NOME_DEPENDENTE)
            })

            return dependents
        }
    },

    methods: {

        change(value) {
            this.$emit('input', value)
        }
    }
}
</script>