<template>
    <div class="flex items-center content-between border-b border-blue px-4 py-5">
        <router-link :to="{ name: 'home' }">
            <img class="header-logo" src="../../assets/pagbank-logo.png" alt="Logo" />
        </router-link>

        <div v-if="codOnix == '5142'" class="plan-data ml-11">
            <strong class="plan-title">Plano Familiar</strong>
            <p class="plan-sub ml-1">Assinatura Mensal</p>
        </div>

        <div v-if="codOnix == '7065'" class="plan-data ml-11">
            <strong>Ação Cartões</strong>
        </div>
        
        <div v-if="codOnix == '7066'" class="plan-data ml-11">
            <strong>Plano Bronze</strong>
        </div>

        <div v-if="codOnix == '7067'" class="plan-data ml-11">
            <strong>Plano Prata</strong>
        </div>

        <div v-if="codOnix == '7068'" class="plan-data ml-11">
            <strong>Plano Ouro</strong>
        </div>

        <div v-if="codOnix == '7112'" class="plan-data ml-11">
            <strong>Seguro BF</strong>
        </div>

        <div v-if="codOnix == '7119'" class="plan-data ml-11">
            <strong>PagControle</strong>
        </div>

        <div v-if="codOnix == '7120'" class="plan-data ml-11">
            <strong>Consultores de Negócios</strong>
        </div>

        <div v-if="codOnix == '7136'" class="plan-data ml-11">
            <strong>Portabilidade</strong>
        </div>

    </div>
</template>


<script>

import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters('account', ['loggedUser']),

        codOnix() {
            if(!this.loggedUser) {
                return null;
            }

            return this.loggedUser.onixCode
        }
    }
}
</script>



<style lang="scss" scoped>
.header-logo {
    height: 2.5rem;
}

// Isso precisa ser melhorado
/*.plan-data {
    border: #73c5e2 1px solid;
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-family: Montserrat;
    color: #000000;
}*/
.plan-title {
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-family: Montserrat;
    color: #000000;
}

.plan-sub {
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-family: Montserrat;
    color: #000000;
}
</style>
