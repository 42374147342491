import Vue from 'vue'

Vue.filter('cpf', function (value) {

    if (!value) return ''
    
    value = value.padStart(11, '0');
    value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

    return value
})

