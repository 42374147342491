<template>
  <Page title="BUSCAR REDE CREDENCIADA" backTo="home" :loading="loading">
    <Dropbox
      name="speciality"
      emptyOption="true"
      label="Selecione a especialidade:"
      :items="specialities"
      v-model="speciality"
    />
    <div class="my-16">
      <Input
        name="zipcode"
        label="Digite o seu CEP:"
        v-mask="'#####-###'"
        :disabled="loading"
        placeholder="00000-000"
        v-model="zipcode"
        @input="handleZipcodeChange"
      />
    </div>

    <div class="mb-16">
      <Input
        name="city"
        label="Nome da Rua / Avenida:"
        placeholder="Nome da Rua"
        v-model="address.route"
        disabled
      />
    </div>

    <button
      class="btn btn-blue btn-lg mb-12"
      @click="search"
      :disabled="canSearch()"
    >
      BUSCAR
    </button>
    <p class="my-6 text-sm" v-if="searched && venues.length <= 0">
      Ops, não encontramos clínicas no CEP informado. Mas não se preocupe que
      não deixaremos você sem atendimento! Você pode pedir um agendamento que
      nós localizaremos clínicas e laboratórios próximos a você!
    </p>
    <p class="my-6 text-sm" v-if="searched && venues.length > 0">
      Localizamos as opções abaixo que são as mais próximas do seu CEP. Porém,
      quando você solicitar um agendamento, faremos uma busca de clínicas ainda
      mais ampla para garantir as melhores opções de atendimento para você!
    </p>
    <div class="flex justify-center items-center ">
      <span class="">{{this.distance}} km</span>
    </div>
    <div class="flex justify-end items-center ">
      <span class="mr-2">Distância Máxima</span>
      <input class="mr-2 ml-2" type="range" min="8" max="100" step="2" @change="updateDistance" :value="distance">
      <!-- <span class="mr-2">km</span> -->
    </div>
    
    <div class="flex justify-end ">
      <span class="mr-2">Mostrar</span>
      <select
        name="perPage"
        v-model="perPage"
        :value="perPage"
        class="w-12"
        @change="resetPagination"
      >
        <option :selected="perPage == 10">10</option>
        <option :selected="perPage == 25">25</option>
        <option :selected="perPage == 50">50</option>
        <option :selected="perPage == 100">100</option>
      </select>
      <span class="mr-1">resultados</span>
    </div>
    

    <div class="mt-10" id="results">
      <div
        v-for="(venue, index) in venues"
        :key="index"
        class="rounded-lg shadow mb-10 px-4"
      >
        <div
          class="text-center font-bold text-xl py-5 px-3 border-b text-gray-500"
        >
          {{ venue.nome }}
        </div>
        <div class="text-left px-2 py-6">
          <h5 class="font-bold mb-1 text-gray-400">Especialidade(s):</h5>
          <p
            class="text-gray-500 font-bold fond-medium leading-5 especialty-text"
          >
            {{ venue.especialidade }}
          </p>
          <br />
          <p class="text-gray-500 fond-medium leading-5">
            {{ venue.endereco }}
            <br />
            CEP {{ venue.cep }}
          </p>

          <p class="text-gray-500 font-bold leading-5">
            <small>
              Em aproximadamente {{ venue.distancia.toFixed(2) }}km
            </small>
            
          </p>

          <div v-if="venue.telephone" class="mt-4">
            <h4 class="font-bold mb-1">Telefone</h4>
            <p class="text-gray-500 fond-medium leading-5">
              {{ venue.telephone }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <p class="my-6 text-sm" v-if="searched && venues.length > 0">
      Caso nenhuma das opções acima lhe atenda, não se preocupe nos vamos
      providenciar um agendamento para você.
    </p>
    <div class="flex justify-end" v-if="total > perPage">
      <span class="mr-1">Mostrando</span>
      <span class="mr-1"
        ><b>{{ perPage }}</b> resultados</span
      >
      <span v-if="total > 0"
        >de <b>{{ total }}</b>
      </span>
    </div>
    <nav
      class="relative z-0 inline-flex shadow-sm -space-x-px"
      aria-label="Pagination"
      v-if="total > perPage"
    >
      <a
        href="#"
        v-if="currentPage - 1 > 0"
        @click="paginationBack"
        class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
      >
        <span class="sr-only">Previous</span>
        <!-- Heroicon name: chevron-left -->
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
            clip-rule="evenodd"
          />
        </svg>
      </a>
      <a
        href="#"
        @click="e => goToPagination(e, n)"
        class="relative inline-flex items-center border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
        v-for="n in Math.ceil(total / perPage)"
        v-bind:key="n"
        v-bind:item="n"
        v-bind:index="n"
      >
        <b v-if="n === currentPage" class="bg-gray-200 w-100 px-4 py-2 ">{{
          n
        }}</b>
        <span v-if="n !== currentPage" class="px-4 py-2 ">{{ n }}</span>
      </a>
      <a
        href="#"
        v-if="currentPage + 1 <= Math.ceil(total / perPage)"
        @click="paginationNext"
        class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
      >
        <span class="sr-only">Next</span>
        <!-- Heroicon name: chevron-right -->
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clip-rule="evenodd"
          />
        </svg>
      </a>
    </nav>
  </Page>
</template>

<script>
import { mapActions } from "vuex";
import Page from "../../components/app/Page";
import Dropbox from "../../components/ui/form/Dropbox";
import Input from "../../components/ui/form/Input";
import { enumEspecialities } from "../../utils/appointmentFormatter";
import { loadZipcode } from "../../helpers";
import { API, AccreditedNetworkList } from "../../api";
import router from "../../router";
import UnauthorizedException from "../../exceptions/UnauthorizedException";

const VueScrollTo = require("vue-scrollto");

export default {
  components: { Page, Input, Dropbox },

  data() {
    return {
      loading: false,
      zipcode: "",
      address: "",
      venues: [],
      venuesTotal: [],
      speciality: undefined,
      specialities: [],
      perPage: 10,
      currentPage: 1,
      total: 0,
      searched: false,
      distance: 8
    };
  },

  mounted() {
    this.loadSpecialities().then(res => {
      this.specialities = res.data.data
        .map(speciality => {
          const mappedSpecialty = enumEspecialities.find(
            e => e.name === speciality.nome.toUpperCase()
          );
          return {
            label: mappedSpecialty
              ? mappedSpecialty.tag.toUpperCase()
              : speciality.nome,
            value: speciality.nome
          };
        })
        .sort((a, b) => {
          let textA = a.label.toUpperCase();
          let textB = b.label.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
    });
  },
  methods: {
    ...mapActions("appointments", ["loadSpecialities"]),

    modelDataResult(data) {
      this.venuesTotal = data.map(data => {
        return {
          nome: data.credenciado[0]["NOME FANTASIA"],
          endereco: `${data.credenciado[0]["LOGRADOURO"]} ${data.credenciado[0]["NUMERO"]},
                    ${data.credenciado[0]["BAIRRO"]}, ${data.credenciado[0]["MUNICIPIO"]} -
                    ${data.credenciado[0]["ESTADO"]}`,
          cep: data.credenciado[0]["CEP"],
          especialidade: data.especialidade.reduce((result, atualEsp, idx) => {
            return idx == 0
              ? atualEsp.ESPECIALIDADE_MEDICA
              : `${result}, ${atualEsp.ESPECIALIDADE_MEDICA}`;
          }, ""),
          distancia: data.credenciado[0]["DISTANCIA"]
        };
      });

      if (this.venuesTotal.length > 10) {
        this.venues = this.venuesTotal.slice(0, this.perPage);
      } else {
        this.venues = this.venuesTotal;
      }
    },

    venuesInPage() {
      let endValue = this.currentPage * this.perPage;
      let startValue = endValue - this.perPage;

      this.venues = this.venuesTotal.slice(startValue, endValue);
    },

    canSearch() {
      if (this.zipcode.length == 9) {
        return false;
      }

      return true;
    },

    resetPagination() {
      this.currentPage = 1;
      this.search();
    },

    updateDistance(e){
      this.distance = e.target.value
    },

    paginationBack(e) {
      this.currentPage -= 1;
      e.preventDefault();
      this.venuesInPage();
      VueScrollTo.scrollTo("#results");
    },

    paginationNext(e) {
      this.currentPage += 1;
      e.preventDefault();
      this.venuesInPage();
      VueScrollTo.scrollTo("#results");
    },

    goToPagination(e, value) {
      e.preventDefault();
      this.currentPage = value;
      this.venuesInPage();
      VueScrollTo.scrollTo("#results");
    },

    handleZipcodeChange() {
      if (this.zipcode.length === 9) {
        this.loading = true;

        loadZipcode(this.zipcode)
          .then(res => {
            if (typeof res.data.data === "object") {
              const address = res.data.data;
              this.address = address;
              this.currentPage = 1;
            } else {
              this.zipcode = "";
              throw new Error("Não é um objeto");
            }
          })
          .catch(() => {
            this.$toasted.show("CEP inválido", {
              position: "bottom-center",
              type: "error",
              duration: 2500,
              fullWidth: true
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    search() {
      this.loading = true;
      this.searched = true;
      API.get(
        AccreditedNetworkList(
          this.address.lat,
          this.address.lng,
          this.speciality,
          this.distance
        ),
        {
          headers: {
            tokenzeus: this.$store.getters["account/tokenzeus"]
          }
        }
      )
        .then(res => {
          this.loading = false;
          const data = res.data;
          this.modelDataResult(data);
          this.currentPage = 1;
          this.total = parseInt(data.length);

          VueScrollTo.scrollTo("#results");
        })
        .catch(err => {
          switch (err.constructor) {
            case UnauthorizedException:
              this.$toasted.show(
                "Seu token expirou. Iniciando revalidação de usuário.",
                {
                  position: "bottom-center",
                  type: "error",
                  duration: 2500,
                  fullWidth: true
                }
              );
              router.push({ name: "login" });
              console.error(err);
              break;
            default:
              this.loading = false;
              this.$toasted.show(
                "Tivemos um problema ao consultar a rede credenciada. Tente novamente mais tarde. Se o problema persistir, você também pode entrar em contato pelo telefone 4003-1775 para capitais e regiões metropolitanas ou 0800 728 2174 para demais regiões.",
                {
                  position: "bottom-center",
                  type: "error",
                  duration: 2500,
                  fullWidth: true
                }
              );
              console.error(err);
              break;
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.especialty-text {
  font-size: 12px;
}
</style>
