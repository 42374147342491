<template>
    <Page back-to="appointment.inperson" title="SOLICITAÇÃO PARA AGENDAMENTO DE CONSULTAS" :steps="4" :current-step="2">
        <h4 class="navigation-subtitle">O que você quer agendar?</h4>

        <form class="mt-12" v-on:submit="submitForm">
            
            <Dropbox name="speciality" emptyOption="true" label="Selecione a especialidade:" :items="specialities" v-model="speciality" required="true" />
            
            <button class="btn btn-blue btn-lg mb-12" type="submit">
                PRÓXIMO
            </button>
        </form>
    </Page>
</template>

<script>
import { mapActions } from 'vuex'
import Page from '../../../components/app/Page'
import Dropbox from '../../../components/ui/form/Dropbox'
import { enumEspecialities } from "../../../utils/appointmentFormatter"

export default {
    components: { Page, Dropbox },

    data() {
        return {
            specialities: []
        }
    },

    mounted() {
        this.loadSpecialities()
        .then((res => {
            this.specialities = res.data.data.map((speciality) => {
                const mappedSpecialty = enumEspecialities.find((e) => e.name === speciality.nome.toUpperCase());
                return ({ label: mappedSpecialty ? mappedSpecialty.tag.toUpperCase() : speciality.nome, value: speciality.nome })
            }).filter(speciality => speciality !== 'VACINAS').sort((a,b) => {
                let textA = a.label.toUpperCase();
                let textB = b.label.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            })
        
        }))
    },

    methods: {

        ...mapActions('appointments', ['loadSpecialities']),

        submitForm(e){
            e.preventDefault();
            this.$router.push({ name: 'appointment.inperson.schedule' })
        },
    },

    computed: {

        speciality: {
            get () {
                return this.$store.state.appointments.speciality
            },
            set (value) {
                this.$store.commit('appointments/updateSpeciality', value)
            }
        },
    }
}
</script>