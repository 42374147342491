import axios from "axios";
import ClientBusinessErrorException from "./exceptions/ClientBusinessErrorException";
import ClientErrorException from "./exceptions/ClientErrorException";
import ServerErrorException from "./exceptions/ServerErrorException";
import UnauthorizedException from "./exceptions/UnauthorizedException";
import router from "./router";

let API = axios.create();

API.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    const HTTPStatusCode = error.response ? error.response?.status : 0;
    let errorResp = error;
    let textError = "";

    switch (true) {
      case HTTPStatusCode === 490:
        errorResp = new ClientBusinessErrorException(
          error.response.data?.error
        );
        break;
      case HTTPStatusCode == 401:
        errorResp = new UnauthorizedException(
          "Não authorizado - token zeus inválido ou expirado"
        );
        break;
      case HTTPStatusCode >= 401 && HTTPStatusCode < 490:
        textError = error.response.data?.error;

        if (
          typeof textError === "string" &&
          textError.includes("(Client error) - ")
        ) {
          textError = textError.replace("(Client error) - ", "");
        }

        errorResp = new ClientErrorException(textError);
        break;
      case HTTPStatusCode >= 500:
        if (error.response.data?.error == "TokenZeus invalido.") {
          router.push({ name: "login" });
        } else {
          errorResp = new ServerErrorException(error.response.data?.error);
        }
        break;
      default:
        console.error(error);
        router.push({ name: "login" });
    }

    return Promise.reject(errorResp);
  }
);

const ApiURLs = {
  QA_LOJA: "https://loja.meutem.com.br",
  QA_API: "https://api.tempravoce.com",
  PAINEL: "https://api.dd.meutem.com.br/v1",
  PROXY_MEUTEM: "https://api.meutem.com.br",
  ZENDESK: "https://temadmcartoes.zendesk.com",
};

const AppointmentsURLs = {
  LOAD_ZIPCODE: (zipcode) => {
    return ApiURLs.QA_LOJA + "/buscaCep/getCep/" + zipcode;
  },

  CREATE_SCHEDULE: ApiURLs.PROXY_MEUTEM + "/app/tickets/appointment",

  CREATE_SCHEDULE_EXAM: ApiURLs.PROXY_MEUTEM + "/app/tickets/exam",

  LIST_SCHEDULE: (cardNumber) => {
    return `${ApiURLs.ZENDESK}/api/v2/search.json?query=custom_field_360015108071:${cardNumber},tags:processo_agendar_consulta`;
  },

  LIST_SCHEDULE_EXAM: (cardNumber) => {
    return `${ApiURLs.ZENDESK}/api/v2/search.json?query=custom_field_360015108071:${cardNumber},tags:processo_agendar_exame`;
  },

  LUCKY_NUMBER: (cardNumber) => {
    return `${ApiURLs.PAINEL}/lucky-number/lucky-number-card/${cardNumber}`;
  },

  EXTRACT_URL: `${ApiURLs.QA_API}/tem_extrato_cto`,

  SPECIALITIES: ApiURLs.PAINEL + "/painel/specialities",
};

const AccountsURLs = {
  ACCREDITED_NETWORK_LIST: ApiURLs.QA_API + "/tem_rede",
  DEPENDENTS_LIST: ApiURLs.QA_API + "/tem_dependente",
  DEPENDENTS_ADHESION: ApiURLs.QA_API + "/tem_adesao",
  UPDATE_ADHESION: ApiURLs.QA_API + "/tem_alteracao",
  USER_TOKEN: ApiURLs.QA_API + "/tem_usertoken_cto",
  UPDATE_STATUS: ApiURLs.QA_API + "/tem_alteracao_status_cto",
  VENUES_SEARCH: ApiURLs.PAINEL + "/painel/clinics/specialities",
  RESET_PASSWORD: ApiURLs.PAINEL + "/card/send-sms-reset-password",
};

const CardURL = (client_channel_id, onix) => {
  return `${ApiURLs.PROXY_MEUTEM}/cards/get-card-by-client-onix-ln/${client_channel_id}/${onix}`;
};

const DrugstoresURLs = {
  SEARCH: ApiURLs.PROXY_MEUTEM + "/app/drugstores/search",
};

const AccreditedNetworkList = (latitude, longitude, specialty, distance) => {
  return `${
    AccountsURLs.ACCREDITED_NETWORK_LIST
  }?distancia=${distance}&longitude=${longitude}&latitude=${latitude}&tipoConsulta=especialidade${
    specialty ? "&especialidadeMedica=" + specialty : ""
  }`;
};

export {
  API,
  ApiURLs,
  AccountsURLs,
  AppointmentsURLs,
  DrugstoresURLs,
  CardURL,
  AccreditedNetworkList,
};
