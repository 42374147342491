import Vue from 'vue'
import Vuex from 'vuex'

import account from './modules/account'
import appointments from './modules/appointments'
import dependents from './modules/dependents'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        account,
        appointments,
        dependents
    }
})

export default store