import Vue from 'vue'
import App from './App.vue'

import axios from 'axios'
import VueAxios from 'vue-axios'

import router from './router'
import store from './store'
import VMask from 'v-mask'
Vue.use(VMask);

Vue.use(VueAxios, axios)

import('./style.scss');
import 'vue-loading-overlay/dist/vue-loading.css';

import VuePersianDatetimePicker from 'vue-persian-datetime-picker';

Vue.use(VuePersianDatetimePicker, {
  name: 'custom-date-picker',
  props: {
    inline: true,
    inputClass: 'time-picker',
    color: '#00acc1',
    autoSubmit: true,
    min: '09:00',
    max: '18:00',
    showNowBtn: false
  }
});

import Notifications from "vt-notifications"
Vue.use(Notifications)

import VueConfirmDialog from 'vue-confirm-dialog'
 
Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

Vue.config.productionTip = false

import Toasted from 'vue-toasted';
Vue.use(Toasted);

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')

