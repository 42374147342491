<template>
  <Page
    back-to="appointment.exams.schedule"
    title="SOLICITAÇÃO PARA AGENDAMENTO DE EXAMES E VACINAS"
    :steps="4"
    :current-step="4"
    :loading="loading"
  >
    <h4 class="navigation-subtitle">Valide as informações</h4>

    <div class="mt-12 text-left font-medium">
      <h1 class="text-gray-500 mb-8">CONFIRME OS DADOS ABAIXO:</h1>

      <h1 class="text-gray-500">NOME:</h1>
      <p>{{ this.personalInfo.dependent }}</p>

      <h1 class="text-gray-500 mt-6">LOCALIZAÇÃO:</h1>
      <p>{{ this.personalInfo.zipcode }}</p>
      <p>{{ this.addressText }}</p>

      <div v-if="hasFile">
        <h1 class="text-gray-500 mt-6">PEDIDO MÉDICO:</h1>
        <p
          class="italic font-bold"
          v-for="_file in this.files"
          :key="_file.name"
        >
          {{ _file.name }}
        </p>
      </div>

      <h1 class="text-gray-500 mt-6">DIA E PERÍODO DE PREFERÊNCIA:</h1>
      <p>{{ this.scheduledDays }} - {{ this.scheduledTimes }}</p>

      <button class="btn btn-blue btn-lg mb-12 mt-16" @click="create">
        SOLICITAR
      </button>
    </div>
  </Page>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import Page from "../../../components/app/Page"
import ClientErrorException from "../../../exceptions/ClientErrorException"

export default {
  components: { Page },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapGetters("appointments", [
      "personalInfo",
      "addressText",
      "speciality",
      "scheduledTimes",
      "scheduledDays",
      "hasFile",
      "files",
    ]),
  },

  methods: {
    ...mapActions("appointments", ["createScheduleExam"]),

    create() {
      this.loading = true

      this.createScheduleExam()
        .then(() => {
          this.$router.push({
            name: "appointment.exams.success",
            params: { message: "Em até 3 dias úteis, nossa equipe entrará em contato por Whats e/ou e-mail. Para acompanhar a sua solicitação, você pode entrar em contato através do telefone 4003-1775 para capitais e regiões metropolitanas ou 0800-7282174 para demais regiões." },                   
          });
          this.$store.commit('appointments/resetState');
        })
        .catch((err) => {
          switch (err.constructor) {
            case ClientErrorException:
              this.$toasted.show("Algum dado informado não está correto.", {
                position: "bottom-center",
                type: "error",
                duration: 2500,
                fullWidth: true,
              })
              console.error(err)
              break
            default:
              this.$toasted.show(
                "Tivemos um problema para agendar seu exame. Tente novamente mais tarde. Se o problema persistir, você também pode entrar em contato pelo telefone 4003-1775 para capitais e regiões metropolitanas ou 0800 728 2174 para demais regiões.",
                {
                  position: "bottom-center",
                  type: "error",
                  duration: 2500,
                  fullWidth: true,
                }
              )
              console.error(err)
              break
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
