<template>
    <div>
        <InputGroup :label="label">
            <select :value="value" @change="handleChange" @input="$emit('input', $event.target.value)" :required="required">
                <option v-if="emptyOption"></option>
                <option
                    v-for="(item, index) in items"
                    :key="index"
                    :selected="item.label !== undefined ? (item.value === value) : (item === value)"
                    :value="item.value !== undefined ? item.value : item"
                >{{item.label !== undefined ? item.label : item}}</option>
            </select>
        </InputGroup>
    </div>
</template>

<script>
import InputGroup from './InputGroup'

export default {
    components: { InputGroup },

    props: ['label', 'items', 'value', 'required', 'emptyOption'],

    methods: {

        handleChange() {
        },
    }
}
</script>
