<template>
  <Page
    back-to="home"
    title="SOLICITAÇÃO PARA AGENDAMENTO DE EXAMES E VACINAS"
    :steps="4"
    :current-step="1"
    :loading="loading"
  >
    <h4 class="navigation-subtitle">Preencha os dados:</h4>

    <form class="mt-12" v-on:submit="submitForm" novalidate>
      <DependentsDropdown
        id="dependent"
        name="dependent"
        label="Para quem é o atendimento:"
        v-model="dependent"
        required="true"
      />

      <router-link
        :to="{
          name: 'account.dependents',
          params: { backTo: 'appointment.exams' },
        }"
        ><div class="mb-16 text-center text-blue underline font-bold">
          ADICIONAR OU EDITAR DEPENDENTES
        </div></router-link
      >

      <div class="mb-16">
        <Input
          id="zipcode"
          name="zipcode"
          label="CEP:"
          v-mask="'#####-###'"
          placeholder="00000-000"
          v-model="zipcode"
          :disabled="loading"
          @input="handleZipcodeChange"
          required="true"
        />
      </div>

      <div class="mb-16">
        <Dropbox
          id="state"
          emptyOption="true"
          name="state"
          label="Estado:"
          :items="states"
          v-model="state"
          required="true"
        ></Dropbox>
      </div>

      <div class="mb-16">
        <Input
          id="city"
          name="city"
          label="Cidade:"
          placeholder="NOME DA CIDADE"
          v-model="city"
          required="true"
        />
      </div>

      <div class="mb-16">
        <Input
          id="district"
          name="district"
          label="Bairro:"
          placeholder="NOME DO BAIRRO"
          v-model="district"
          required="true"
        />
      </div>

      <button class="btn btn-blue btn-lg mb-12" type="submit">
        PRÓXIMO
      </button>
    </form>
  </Page>
</template>

<script>
import { mapActions } from "vuex"
import Page from "../../../components/app/Page"
import Dropbox from "../../../components/ui/form/Dropbox"
import DependentsDropdown from "../../../components/common/DependentsDropdown"
import Input from "../../../components/ui/form/Input"
import { loadZipcode } from "../../../helpers"

export default {
  components: { DependentsDropdown, Page, Dropbox, Input },

  data() {
    return {
      loading: false,
      states: [
        "AC",
        "AL",
        "AP",
        "AM",
        "BA",
        "CE",
        "DF",
        "ES",
        "GO",
        "MA",
        "MT",
        "MS",
        "MG",
        "PA",
        "PB",
        "PR",
        "PE",
        "PI",
        "RJ",
        "RN",
        "RS",
        "RO",
        "RR",
        "SC",
        "SP",
        "SE",
        "TO",
      ],
    }
  },

  mounted() {
    this.loadDependents()
  },

  methods: {
    ...mapActions("dependents", ["loadDependents"]),
    submitForm: function(e) {
      e.preventDefault()
      this.$router.push({ name: "appointment.exams.upload" })
    },
    handleZipcodeChange: function() {
      if (this.zipcode.length == 9) {
        this.loading = true

        loadZipcode(this.zipcode)
          .then((res) => {
            if (typeof res.data.data === "object") {
              const address = res.data.data
              this.city = address.city
              this.state = address.uf
              this.district = address.sublocality
            } else {
              this.zipcode = ""
              throw new Error("Não é um objeto")
            }
          })
          .catch(() => {
            this.$toasted.show("CEP inválido", {
              position: "bottom-center",
              type: "error",
              duration: 2500,
              fullWidth: true,
            })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
  },

  computed: {
    zipcode: {
      get() {
        return this.$store.state.appointments.personalInfo.zipcode
      },
      set(value) {
        this.$store.commit("appointments/updateZipcode", value)
      },
    },

    dependent: {
      get() {
        return this.$store.state.appointments.personalInfo.dependent
      },
      set(value) {
        this.$store.commit("appointments/updateDependent", value)
      },
    },

    state: {
      get() {
        return this.$store.state.appointments.personalInfo.state
      },
      set(value) {
        this.$store.commit("appointments/updateState", value)
      },
    },

    city: {
      get() {
        return this.$store.state.appointments.personalInfo.city
      },
      set(value) {
        this.$store.commit("appointments/updateCity", value)
      },
    },

    district: {
      get() {
        return this.$store.state.appointments.personalInfo.district
      },
      set(value) {
        this.$store.commit("appointments/updateDistrict", value)
      },
    },
  },
}
</script>
