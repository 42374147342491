<template>
    <div class="main">
        <Page back-to="home" title="SOLICITAÇÃO DE ATENDIMENTO MÉDICO POR VÍDEO" :steps="1" :current-step="1">
            <DependentsDropdown name="dependent" label="Para quem é o atendimento:" v-model="dependent" required="true" />
            
            <router-link :to="{ name: 'account.dependents', params: { backTo: 'appointment.video' } }"><div class="mb-16 text-center text-blue underline font-bold">ADICIONAR OU EDITAR DEPENDENTES</div></router-link>                    

            <button class="btn btn-blue btn-lg mb-12" type="button" v-on:click="attendance">
                PRÓXIMO
            </button>
        </Page>

        <transition name="fade">
            <div class="modal" v-if="modalTerm">
                <div class="modal__backdrop">
                    <div class="modal__dialog">
                        <div class="modal-body">
                            <div class="modal-header">
                                <h5 class="modal-title">Termo de aceite da Telemedicina</h5>
                            </div>
                            <div class="modal-body response_conexa">
                                <div class="modal-term" style="overflow: auto; height: 400px">
                                <Term />
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button
                                type="button"
                                class="btn-modal btn-secondary"
                                v-on:click="cancel_acceptance"
                                >
                                Cancelar
                                </button>
                                <button
                                type="button"
                                class="btn-modal btn-primary"
                                v-on:click="contract_accepted"
                                >
                                Aceitar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal" v-if="modalVideoChamada">
                <div class="modal__backdrop">
                    <div class="modal__dialog">
                        <div class="modal-body">
                            <div class="modal-header">
                                <h5 class="modal-title">Video chamada</h5>
                            </div>
                            <div class="modal-body response_conexa_video">
                                <p id="custom_text">
                                Iniciando atendimento via video chamada...
                                </p>
                            </div>
                            <div class="modal-footer">
                                <button
                                type="button"
                                class="btn-modal btn-primary"
                                v-on:click="closeModalVideoChamada"
                                >
                                Cancelar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal" v-if="modalVideoChamadaNtoFound">
                <div class="modal__backdrop">
                    <div class="modal__dialog">
                        <div class="modal-body">
                        <div class="modal-header">
                            <h5 class="modal-title">Video chamada</h5>
                        </div>
                        <div class="modal-body response_conexa">
                            <p>O seu cadastro não foi encontrado.</p>
                            <p>entre em contato com a central.</p>
                        </div>
                        <div class="modal-footer">
                            <button
                            type="button"
                            class="btn-modal btn-primary"
                            v-on:click="closeModalVideoChamada"
                            >
                            Cancelar
                            </button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>

        <input id="start_conexa" type="hidden" value="1" />
    </div>
</template>

<script>
import { mapActions } from "vuex"
import Page from '../../../components/app/Page'
import DependentsDropdown from '../../../components/common/DependentsDropdown'
import { mapGetters } from 'vuex'
import Term from "../../../components/app/Term";

export default {
  components: { Term, DependentsDropdown, Page },
  computed: {
    ...mapGetters("account", ["loggedUser"]),

    userName() {
      return this.loggedUser.name;
    },

    codOnix() {
      if (!this.loggedUser) {
        return null;
      }

      return this.loggedUser.onixCode;
    },
  },
  name: "Modal",
  data() {
    return {
      urlApiConexa: "https://loja.meutem.com.br",
      queue: false,
      modalVideoChamadaNtoFound: false,
      modalVideoChamada: false,
      modalTerm: false,
      dependent: '',
    };
  },

  mounted() {
    this.loadDependents();
    this.startQueue();
  },

  methods: {
    ...mapActions("dependents", ["loadDependents"]),

    getWhoIsTheServiceFor() {
        const dependent = this.$store.getters['dependents/dependents'].find((dependent) => dependent.NOME_DEPENDENTE === this.dependent)
        return dependent ? dependent.CPF_DEPENDENTE : this.loggedUser.cpf;
    },

    attendance() {
      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Transaction-Key":
            "njRRmvsxpZyUbU2fUsdbppEaMeNkLLARkNQg44Wks7rbscHNmL",
        },
        body: JSON.stringify({
          cpf: this.getWhoIsTheServiceFor(),
          channel_id: 147,
        }),
      };
      fetch(this.urlApiConexa + "/cliente/ajaxApiCheckAcceptanceTerm", options)
        .then((response) => response.json())
        .then((data) => {
          if (data == 1) {
            this.openModalTerm();
          } else {
            this.start_queue_conexa();
          }
        })
        .catch((error) => {
          console.log("error ajaxCheckAcceptanceTerm: " + error);
        });
    },

    cancel_acceptance() {
      this.$el.querySelector("#start_conexa").value = 1;
      this.closeModalTerm();
    },

    contract_accepted() {
      this.closeModalTerm();

      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Transaction-Key":
            "njRRmvsxpZyUbU2fUsdbppEaMeNkLLARkNQg44Wks7rbscHNmL",
        },
        body: JSON.stringify({
          cpf: this.getWhoIsTheServiceFor(),
          channel_id: 147,
        }),
      };
      fetch(this.urlApiConexa + "/cliente/ajaxApiContractAccepted", options)
        .then((response) => response.json())
        .then(this.start_queue_conexa())
        .catch((error) => {
          console.log("error ajaxContractAccepted: " + error);
        });
    },

    start_queue_conexa() {
      this.openModalVideoChamada();

      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Transaction-Key":
            "njRRmvsxpZyUbU2fUsdbppEaMeNkLLARkNQg44Wks7rbscHNmL",
        },
        body: JSON.stringify({
          cpf: this.getWhoIsTheServiceFor(),
          onix: this.loggedUser.onixCode,
        }),
      };

      fetch(this.urlApiConexa + "/cliente/ajaxApiAddUserQueueConexa", options)
        .then((response) => response.json())
        .then((data) => {
          try {
            if (
              typeof data.status_code != "undefined" &&
              data.status_code == 200
            ) {
              this.$el.querySelector("#start_conexa").value = 2;
            } else {
              return false;
            }
          } catch (err) {
            console.log("error ajaxApiAddUserQueueConexa: " + err);
            this.error_videochamada();
          }
        })
        .catch((error) => {
          console.log("error ajaxApiAddUserQueueConexa: " + error);
        });
    },

    openModalTerm() {
      this.modalTerm = true;
      document.querySelector("body").classList.add("overflow-hidden");
    },

    closeModalTerm() {
      this.modalTerm = false;
      document.querySelector("body").classList.remove("overflow-hidden");
    },

    openModalVideoChamada() {
      this.modalVideoChamada = true;
      document.querySelector("body").classList.add("overflow-hidden");
    },

    closeModalVideoChamada() {
      this.modalVideoChamada = false;
      document.querySelector("body").classList.remove("overflow-hidden");
      this.ajaxApiCancelConexa();
    },

    startQueue() {
      this.queue = setInterval(() => {
        this.ajaxGetUrlConexa();
      }, 15000);
    },
    
    ajaxGetUrlConexa() {
      if (this.$el.querySelector("#start_conexa")?.value == 2) {
        const options = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Transaction-Key":
              "njRRmvsxpZyUbU2fUsdbppEaMeNkLLARkNQg44Wks7rbscHNmL",
          },
          body: JSON.stringify({
            cpf: this.getWhoIsTheServiceFor(),
            onix: this.loggedUser.onixCode,
          }),
        };
        fetch(this.urlApiConexa + "/cliente/ajaxApiGetUrlConexa", options)
          .then((response) => response.json())
          .then((data) => {
            try {
              if (typeof data[0].code != "undefined" && data[0].code == "469") {
                this.$el.querySelector("#start_conexa").value = 1;
              }
            } catch (err) {
              console.log(err);
            }

            if (this.$el.querySelector("#start_conexa").value == 2) {
              try {
                var response_html = "<p>Bem - Vindo!</p>";
                response_html =
                  response_html +
                  "<p>Sua posição na fila para atendimento é " +
                  data.appointment.posicaoFila +
                  ". Tempo estimado de " +
                  data.appointment.horaPrevistaAtendimento +
                  "";
                document.querySelector(
                  ".response_conexa_video"
                ).innerHTML = response_html;

                if (data.call.zoomMeetingUrlPatient !== undefined) {
                  this.$el.querySelector("#start_conexa").value = 1;
                  window.location.href = data.call.zoomMeetingUrlPatient;
                }
              } catch (err) {
                console.log("error ajaxGetUrlConexa: " + err);
              }
            }
          })
          .catch((error) => {
            console.log("error ajaxApiGetUrlConexa: " + error);
          });
      }
    },

    ajaxApiCancelConexa() {
      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Transaction-Key":
            "njRRmvsxpZyUbU2fUsdbppEaMeNkLLARkNQg44Wks7rbscHNmL",
        },
        body: JSON.stringify({
          cpf: this.getWhoIsTheServiceFor(),
          onix: this.loggedUser.onixCode,
        }),
      };
      fetch(this.urlApiConexa + "/cliente/ajaxApiCancelConexa", options)
        .then((response) => response.json())
        .then((data) => {
          console.log("video chamada cancelada: " + data);
        })
        .catch((error) => {
          console.log("error ajaxApiCancelConexa: " + error);
        });
    },

    error_videochamada() {
      this.$el.querySelector("#start_conexa").value = 1;

      this.modalVideoChamada = false;
      document.querySelector("body").classList.remove("overflow-hidden");

      this.modalVideoChamadaNtoFound = true;
      document.querySelector("body").classList.add("overflow-hidden");

      this.ajaxApiCancelConexa();
    },
  }
};
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/form.scss';
</style>