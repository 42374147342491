<template>
<Page >
    <router-link :to="{name: 'surgery'}">
        <div class="ml-1 mt-4 app-container flex text-blue  font-bold d-flex align-items-center">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" class="svg-inline--fa fa-chevron-left fa-w-2 w-3" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path></svg>
            <div class="ml-2">VOLTAR</div>
        </div>
    </router-link>
    
    <img width="100" class="mt-4 ml-3 header-logo" src="../assets/pagbank-logo.png" alt="Logo" />
    <div class="p_left ml-3">
        <p class="mt-8 green"><b>Conheça a Vidia</b></p>
        <b>
            <br>
            <p>A Vidia é uma empresa que viabiliza procedimentos cirúrgicos de baixa e média complexidade para pessoas que não possuem plano de saúde e precisam ou desejam realizá los e oferece mais de 400 cirurgias eletivas, entre elas, estão os procedimentos como colecistectomia (retirada da vesícula biliar), hérnias abdominais (inguinal e umbilical), catarata, cirurgia refrativa, cirurgia de fimose e reversão de vasectomia.</p> 
            <br>
            <p>O PagBank Saúde possui preços diferenciados que o cliente pode contratar com preços mais acessíveis e facilidade no pagamento.</p> 
            <br>
            <p>Os hospitais parceiros da Vidia estão localizados na Grande São Paulo, mas estamos com um plano de expansão para outras capitais nos próximos meses.</p> 
            <br>
            <p>Os hospitais parceiros são:</p> 
            <br>
            <p>&bull; Hospital Alemão Oswaldo Cruz Unidade Vergueiro</p> 
            <p>Endereço: Rua São Joaquim, Liberdade, São Paulo, 01508 000</p> 
            <br>
            <p>&bull; Portellinha Oftalmologia Endereço: Avenida Indianópolis, 489 Indianópolis, São Paulo, 04063 000</p> 
            <br>
            <p>&bull; UPO Oftalmologia Endereço: Avenida Brigadeiro Luís Antônio, 2504 Jardins, São Paulo, 01402 000</p> 
            <br>
            <p>&bull;BG Serviços Médicos</p>
            <p> Endereço: Rua Domingos de Morais, 2781 14 º andar, Vila Mariana, São Paulo, 04035 001</p> 
            <br>
        </b>
        <p class="green"><b>Conheça mais sobre os Hospitais:</b></p>
        <b>
            <br>
            <p>&bull; Hospital Alemão Oswaldo Cruz Unidade Vergueiro</p>
            <p>Possui mais de 200 leitos, sendo 30 deles de UTI O Centro Cirúrgico é composto com 13 salas cirúrgicas e preparadas para cirurgias de média e alta complexidade. A unidade dispõe de ambulatório de especialidades com consultórios, salas de endoscopia, boxes para o tratamento de quimioterapia, além de um centro de diagnóstico por imagem com realização de exames de Ressonância Magnética, Tomografia Computadorizada, Raio X, Ultrassonografia, Mamografia, Broncoscopia, Endoscopia e Colonoscopia</p>
            <br>
            <p>&bull; Portellinha Oftalmologia</p>
            <p>A clínica foi fundada em 1978 pelo Dr Waldir Portellinha e possui reconhecimento pela competência e seriedade no tratamento, diagnósticos e cirurgias oculares.</p>
            <p>É composta por modernas instalações, com aparelhos de última geração para todos os exames oftalmológicos além de uma equipe altamente treinada, recepção informatizada para atendimento diferenciado aos pacientes Conta com profissionais oftalmologistas e anestesistas com títulos de especialistas das respectivas áreas e reconhecidos pelo CBO (Conselho Brasilieiro de Oftalmologia) e AMB (Associação Médica Brasileira)</p>
            <br>
            <p>&bull; UPO Oftalmologia</p>
            <p>Presta atendimento e serviços em todas as áreas da Oftalmologia Conta com um centro de diagnóstico avançado e personalizado e com profissionais experientes e treinados nos melhores institutos nacionais e internacionais, com especialização no tratamento e cirurgia de Catarata, Vitrectomia, Glaucoma, Miopia, Estrabismo, Hipermetropia, Astigmatismo, Tumores Oculares/ Pálpebras, Plástica Ocular</p>
            <br>
            <p>&bull; BG Serviços Médicos</p>
            <p>Clínica fundada pelo Dr Bruno Garcia, formado em Medicina pela Universidade Federal de Sergipe, Residência Médica em Cirurgia Geral pela Fundação de Beneficência Hospital de Cirurgia e Urologia pelo Hospital do Servidor Público Municipal de São Paulo, com pós graduação em Cirurgia Robótica e Observership in Robotic Surgery University of Southern California (Atendimento voltado para especialidade de Urologia, através de procedimentos ambulatoriais e de baixo risco.</p>
        </b>
        <br>
    </div>    
    </Page>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters('account', ['loggedUser']),

        userName() {

            return this.loggedUser.name
        }
    },
     methods: {
        needClear() {
            if(typeof this.to === 'object' && this.to.name === "home"){
                this.$store.commit('appointments/resetState')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.main {
    background-color: #F7F7F7;
}

.hero {
    background-color: #CCE5E4;

    img { 
        width: 320px;
    }
}
</style>