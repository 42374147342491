import moment from "moment"

export const datesMixin = {
  methods: {
    convertDate(date, format) {
      if (!date) {
        return "-"
      }

      return moment(date, "YYYY-MM-DD").format(format)
    },

    rawDate(date, format = "DD/MM/YYYY") {
      return moment(date, format).format("YYYY-MM-DD")
    },
  },
}
