<template>
    <div class="app-container">
        <center>
            <div class="w-100 flex justify-center mt-20 mb-10">
                <img class="success" src="../assets/pagbank-success.png" alt="Sucesso">
            </div>

            <h1 class="navigation-title">PAGAMENTO REALIZADO</h1>

            <h1 class="text-gray-500 font-bold mt-10 mb-20 text-lg px-14">{{ successMessage }}</h1>

            <router-link v-on:click.native="needClear" :to="{ name: 'home' }"><button class="btn btn-blue btn-lg mb-16">ENTENDI</button></router-link>
        </center>
    </div>
</template>

<script>
export default {
    props: [ 'message' ],

    computed: {

        successMessage() {

            return this.message || 'Pagamento efetuado com sucesso!<br> O valor estará disponível em até 24h.'
        }
    },

    methods: {
        needClear() {
            this.$store.commit('appointments/resetState')
        }
    }
}
</script>

<style lang="scss" scoped>
    img.success {
        max-width: 150px;
        max-height: 150px;
    }
</style>