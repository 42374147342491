<template>
    <div>
        <Loading :loading="loading" />

        <BackBar v-if="backTo" :to="backRoute" />

        <div class="app-container">
            <h1 v-if="title" class="navigation-title my-5">{{ title }}</h1>

            <div v-if="steps" class="flex justify-center py-5">
                <Steps :steps="steps" :current="currentStep"></Steps>
            </div>

            <slot />
        </div>
    </div>
</template>

<script>
import Loading from './Loading'
import BackBar from './BackBar'
import Steps from '../ui/Steps'

export default {

    components: { Loading, BackBar, Steps },

    props: {
        loading: Boolean,
        title: String,
        backTo: [Object, String],
        steps: Number,
        currentStep: Number
    },
    
    data () {
        return {}
    },

    computed: {
        backRoute() {
            if (typeof this.backTo === 'string') {
                return { name: this.backTo }
            }
            else {
                return this.backTo
            }
        }
    }
}
</script>