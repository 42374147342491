<template>
  <div>
    <Loading :loading="true" :opacity="1.0" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "../../components/app/Loading";
import { API, CardURL } from "../../api";

const formatCpf = (stringCpf = "") => {
  var regex = /[.-\s]/g;
  var result = stringCpf.replace(regex, "");

  return result;
};

export default {
  components: { Loading },

  mounted() {
    this.axios
      .post("https://api.dd.meutem.com.br/v1/api-auth/login", {
        companyId: "1286918",
        apiKey: "SyQOihBKkK88zAW82OLJ",
      })
      .then((response) => {
        const tokenzeus = response.data.data.token;
        const usertoken = this.userToken;

        console.log('v1/api-auth/login ', { tokenzeus })

        this.axios
          .get("https://loja.meutem.com.br/clients/apiLoginPagsaude", {
            params: {
              userToken: usertoken,
              zeusToken: tokenzeus,
            },
            withCredentials: false,
          })
          .then((response) => {
            const user = response.data.response;

            console.log('apiLoginPagsaude', { user });

            this.axios
              .get("https://loja.meutem.com.br/cliente/apiMeusDados", {
                params: {
                  client_channel_id: user.client_channel_id,
                  channel_id: user.channel_id,
                  userToken: usertoken,
                  zeusToken: tokenzeus,
                },
                withCredentials: false,
              })
              .then((response) => {
                const account = response.data;

                console.log('account', { account })

                this.$store.commit("account/setLogged", {
                  tokenzeus,
                  loggedUser: {
                    id: user.client_channel_id,
                    name: user.client_channel_name,
                    gender: user.client_channel_gender,
                    birthDate: account.cliente.birthdate,
                    email: account.cliente.email,
                    phone: account.cliente.telephone_1,
                    cpf: formatCpf(account.cliente.cpf),
                    onixCode: user.onix_code,
                  },
                  account,
                });

                API.get(
                  CardURL(
                    user.client_channel_id,
                    this.$store.getters["account/onixCode"]
                  ),
                  {
                    headers: {
                      "Transaction-Key":
                        "njRRmvsxpZyUbU2fUsdbppEaMeNkLLARkNQg44Wks7rbscHNmL",
                    },
                  }
                )
                  .then((response) => {
                    const cardData = response.data;

                    if (cardData.length > 0) {
                      const loggedUser = this.$store.getters[
                        "account/loggedUser"
                      ];

                      this.$store.commit("account/setLogged", {
                        tokenzeus,
                        loggedUser: {
                          ...loggedUser,
                          cardNumber: cardData[0].number,
                          emitter: cardData[0].emitter,
                          validator: cardData[0].validator,
                          product: cardData[0].product,
                          way: cardData[0].way,
                        },
                      });

                      this.$router.push({ name: "home" });
                    } else {
                      throw new Error("Cartão não encontrado");
                    }
                  })
                  .catch(this.handleLoginFailed);
              })
              .catch(this.handleLoginFailed);
          })
          .catch(this.handleLoginFailed);
      })
      .catch(this.handleLoginFailed);
  },

  methods: {
    handleLoginFailed(err) {
      console.error(err);
      this.$store.dispatch("account/resetUserToken");
      this.$router.push({ name: "login.error" });
    },
  },

  computed: {
    ...mapGetters("account", ["userToken", "loggedUser"]),
  },
};
</script>
