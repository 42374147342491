<template>
	<Page back-to="home" :loading="loading">
<!--		<h4 class="mt-4 navigation-subtitle f-title">
			Serviço indisponível no momento, entre em contato com a nossa central de atendimento para realizar a recarga.<br>
			4003-1775 para capitais e regiões metropolitanas ou<br>
			0800-728-2174 para demais regiões<br>
		</h4>
-->
		<h4 class="mt-4 navigation-subtitle f-title">
			CARREGAR MEU<br />PAGBANK SAÚDE
		</h4>

		<p class="balance">
			<b><span class="fb">Saldo:</span> R$ <span id="amount"></span></b>
		</p>

		<form>
			<div class="ml-3 mr-3 mb-4">
				<div class="input-group">
					<label>Valor</label>
					<input
						v-model="valuetotal"
						v-on:keypress="isNumber(event)"
						v-on:keyup="checkKeyUp"
						placeholder="R$0,00"
						id="valuetotal"
					/>
				</div>
			</div>

			<div id="choose_value">
				<span class="ml-3">Escolha o valor:</span>

				<button
					v-on:click="calculateValue('20')"
					type="button"
					class="btn btn-outline-primary mt-2 border border-primary"
				>
					R$20,00
				</button>
				<button
					v-on:click="calculateValue('30')"
					type="button"
					class="btn btn-outline-primary mt-2 border border-primary"
				>
					R$30,00
				</button>
				<button
					v-on:click="calculateValue('50')"
					type="button"
					class="btn btn-outline-primary mt-2 border border-primary"
				>
					R$50,00
				</button>
				<button
					v-on:click="calculateValue('100')"
					type="button"
					class="btn btn-outline-primary mt-2 border border-primary"
				>
					R$100,00
				</button>
				<button
					v-on:click="anotherValue()"
					type="button"
					class="btn btn-outline-primary mt-2 border border-primary"
				>
					Outro valor
				</button>

				<button
					class="btn btn-blue btn-lg mt-8 mb-12"
					id="next"
					type="button"
					v-on:click="next()"
					disabled
				>
					PRÓXIMO
				</button>
			</div>
		</form>

		<form id="pay_form">
			<div class="ml-3 mr-3 mb-4">
				<div>
					Informe seu cartão de crédito para<br />
					pagamento e a quantidade de parcelas:
				</div>
				<div class="input-group">
					<label>Número do cartão</label>
					<input
						v-model="number_card"
						v-on:keypress="isNumber(event)"
						maxlength="20"
						id="number_card"
						v-on:keyup="checkFields"
					/>
				</div>
			</div>
			<div class="ml-3 mr-3 mb-4">
				<div class="input-group">
					<label>Nome impresso no cartão</label>
					<input v-model="name_card" v-on:keyup="checkFields" />
				</div>
			</div>

			<div class="ml-3 mr-3 mb-4">
				<div class="input-group">
					<label>Bandeira do cartão</label>
					<select v-model="card_brand" @change="checkFields()">
						<option value="AMEX">AMEX</option>
						<option value="AURA">AURA</option>
						<option value="DINERS">DINERS</option>
						<option value="CIELO_ELO">ELO</option>
						<option value="MASTERCARD">MASTERCARD</option>
						<option value="VISA">VISA</option>
						<option value="DISCOVER">DISCOVER</option>
					</select>
				</div>
			</div>

			<div class="ml-3 mr-3 mb-4 card-top">
				<selection class="flex">
					<div class="input-group">
						<label>Venc.</label>
						<input
							v-model="due"
							id="due"
							placeholder="00/0000"
							v-mask="'##/####'"
							v-on:keyup="checkFields(), checkDue()"
							v-on:keypress="isNumber(event)"
							size="5"
							maxlength="7"
						/>
					</div>
					<div class="input-group ml-2">
						<label>CVV</label>
						<input
							v-model="cvv"
							v-on:keypress="isNumber(event)"
							v-on:keyup="checkFields"
							id="cvv"
							size="5"
							maxlength="4"
						/>
					</div>
					<div class="input-group ml-2">
						<label>NºParc.</label>
						<select
							v-model="parcela"
							id="parcela"
							@change="checkFields()"
							maxlength="3"
							style="padding: 1.05rem"
						>
							<option value="1">1</option>
							<option value="2">2</option>
							<option value="3">3</option>
							<option value="4">4</option>
							<option value="5">5</option>
						</select>
					</div>
				</selection>
				<div class="parcela">Parcela mínima de R$20,00</div>
			</div>

			<button
				class="btn btn-blue btn-lg mt-8 mb-12"
				id="pay"
				type="button"
				v-on:click="pay()"
				disabled
			>
				CONFIRMAR
			</button>
		</form>

		<input type="hidden" v-model="address" name="address" />
		<input type="hidden" v-model="number" name="number" />
		<input type="hidden" v-model="postal_code" name="cep" />
		<input type="hidden" v-model="city" name="city" />
		<input type="hidden" v-model="state" name="uf" />
		<input type="hidden" v-model="neighborhood" name="neighborhood" />

	</Page>
</template>

<script>
import { mapGetters } from "vuex";
import Page from "../components/app/Page";
import { differenceMinutes } from "../utils/differenceDate";
//import Input from '../components/ui/form/Input'

export default {
	components: { Page },
	data() {
		return {
			neighborhood: "",
			state: "",
			city: "",
			postal_code: "",
			complement: "",
			number: "",
			address: "",
			card_brand: "",
			valuetotal: "",
			number_card: "",
			name_card: "",
			due: "",
			cvv: "",
			parcela: "",
			loading: false,
		};
	},
	mounted() {
		const headersOptions = {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Transaction-Key":
					"njRRmvsxpZyUbU2fUsdbppEaMeNkLLARkNQg44Wks7rbscHNmL",
			},
		};
		fetch(
			"https://api.meutem.com.br/cards/get-statement/" + this.cardNumber,
			headersOptions
		)
			.then((response) => response.json())
			.then((data) => {
				document.getElementById("amount").innerHTML =
					data.statement[0].amount;
			})
			.catch((error) => {
				console.log("error statement:" + error);
			});

		const addressOptions = {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Transaction-Key":
					"njRRmvsxpZyUbU2fUsdbppEaMeNkLLARkNQg44Wks7rbscHNmL",
			},
		};

		fetch(
			"https://api.meutem.com.br/get-client-address/" +
				this.loggedUser.id,
			addressOptions
		)
			.then((response) => response.json())
			.then((data) => {
				if (data.neighborhood) {
					this.neighborhood = data.neighborhood;
				}
				if (data.state) {
					this.state = data.state;
				}
				if (data.city) {
					this.city = data.city;
				}
				if (data.postal_code) {
					var postal_code = data.postal_code;
					var zipcode = "";
					zipcode = postal_code.substring(0, 5) + "-";
					zipcode += postal_code.substring(5, 8);
					this.postal_code = zipcode;
				}
				if (data.complement) {
					this.complement = data.complement;
				}
				if (data.number) {
					this.number = data.number;
				}
				if (data.address) {
					this.address = data.address;
				}
			})
			.catch((error) => {
				console.log("error address:" + error);
			});
	},
	methods: {
		calculateValue: function (value) {
			var sub_total;
			if (!document.getElementById("valuetotal").value) {
				sub_total = 0;
			} else {
				sub_total = document.getElementById("valuetotal").value;
			}

			sub_total = sub_total.toString().replace("R$", "");

			var total = parseFloat(sub_total.trim()) + parseFloat(value);

			if (parseFloat(total) > 19) {
				document.getElementById("next").disabled = false;
			} else {
				document.getElementById("next").disabled = true;
			}
			var valorFormatado = total.toLocaleString("pt-BR", {
				style: "currency",
				currency: "BRL",
			});
			document.getElementById("valuetotal").value = valorFormatado;
			this.valuetotal = valorFormatado;
		},
		anotherValue: function () {
			document.getElementById("valuetotal").focus();
		},
		isNumber: function (evt) {
			evt = evt ? evt : window.event;
			var charCode = evt.which ? evt.which : evt.keyCode;
			if (
				charCode > 31 &&
				(charCode < 48 || charCode > 57) &&
				charCode !== 46
			) {
				evt.preventDefault();
			} else {
				return true;
			}
		},
		checkParcela: function () {
			var parcela = document.getElementById("parcela").value;
			parcela = parseFloat(parcela.trim());
			if (parcela > 5) {
				document.getElementById("parcela").value = 5;
			}
		},
		checkDue: function () {
			var due = document.getElementById("due");
			var day = "";
			if (due.value.length == 2) {
				day = due.value;
				due.value = day + "/";
			}
		},
		checkKeyUp: function () {
			var sub_total;

			if (!document.getElementById("valuetotal").value) {
				document.getElementById("next").disabled = true;
				document.getElementById("valuetotal").focus();
				return false;
			} else {
				sub_total = document.getElementById("valuetotal").value;
				sub_total = sub_total.toString().replace("R$", "");
				var total = parseFloat(sub_total.trim());
				if (total > 19) {
					document.getElementById("next").disabled = false;
					return false;
				} else {
					document.getElementById("valuetotal").focus();
					document.getElementById("next").disabled = true;
					return false;
				}
			}
		},
		checkFields: function () {
			var valuetotal = document.getElementById("valuetotal").value;

			if (
				valuetotal &&
				this.number_card &&
				this.name_card &&
				this.due &&
				this.cvv &&
				this.parcela &&
				this.card_brand
			) {
				document.getElementById("pay").disabled = false;
			} else {
				document.getElementById("pay").disabled = true;
			}
			return false;
		},
		next: function () {
			document.getElementById("choose_value").style.display = "none";
			document.getElementById("pay_form").style.display = "block";
		},
		checkValidTransaction: function (total) {
			const dateNow = new Date();
			const lastTransaction = JSON.parse(
				sessionStorage.getItem("lastRecharge")
			);

			if (!lastTransaction) return true;

			if (
				lastTransaction.total === total ||
				dateNow === lastTransaction.date ||
				differenceMinutes(dateNow, new Date(lastTransaction.date)) <= 1
			) {
				return false;
			}

			return true;
		},
		pay: function () {
			var due = document.getElementById("due").value;
			var expires_month = due.substring(0, 2);
			var expires_year = due.substring(3, 7);

			var sub_total = document.getElementById("valuetotal").value;
			sub_total = sub_total.toString().replace("R$", "");
			sub_total = sub_total.toString().replace(",", ".");
			var total = parseFloat(sub_total.trim());

			if (!this.checkValidTransaction(total)) {
				this.$toasted.show(
					"Uma recarga nesse mesmo valor já foi feita. Tente outro valor ou aguarde.",
					{
						position: "bottom-center",
						type: "error",
						duration: 2500,
						fullWidth: true,
					}
				);
				return;
			}

			this.loading = true;
			const payOptions = {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Transaction-Key":
						"njRRmvsxpZyUbU2fUsdbppEaMeNkLLARkNQg44Wks7rbscHNmL",
				},
				body: JSON.stringify({
					client_id: this.id,
					total_amount: total,
					valor_total: total,
					is_recharge: "1",
					tax: "0",
					slip_tax: "2.5",
					installment_amount: "",
					payment_option: "card",
					local: "site",
					card_holder_name: this.name_card,
					card_number: this.number_card,
					card_cvv: this.cvv,
					card_brand: this.card_brand,
					expires_month: expires_month,
					expires_year: expires_year,
					installments: this.parcela,
					billing_cpf: this.cpf,
					billing_birthdate: this.birthdate,
					billing_telephone: this.phone,
					card_holder_email: this.email,
					billing_postalCode: this.postal_code,
					billing_address: this.address,
					billing_number: this.number,
					billing_complement: this.complement,
					billing_neighborhood: this.neighborhood,
					billing_city: this.city,
					billing_state: this.state,
					voxcred_card_number: "",
					voxcred_password_card: "",
					urlReturn: "",
					slip_email: "",
				}),
			};
			fetch(
				"https://api.meutem.com.br/selling/comprarCreditoPayZen",
				payOptions
			)
				.then((response) => response.json())
				.then((data) => {
					console.log("comprarCreditoPayZen message:", data.message);
					console.log("comprarCreditoPayZen status:", data.status);

					this.loading = false;
					if (data.status >= 200 || data.status < 201) {
						sessionStorage.setItem(
							"lastRecharge",
							JSON.stringify({
								date: new Date().getTime(),
								total,
							})
						);
						window.location.href = "/credit.card.success";
					} else {
						window.location.href = "/credit.card.error";
					}
				})
				.catch((error) => {
					this.loading = false;
					console.error("error: " + error);
				});
		},
	},
	computed: {
		...mapGetters("account", ["loggedUser"]),

		userName() {
			return this.loggedUser.name;
		},
		cardNumber() {
			return this.loggedUser.cardNumber;
		},
		id() {
			return this.loggedUser.id;
		},
		cpf() {
			var cpf = this.loggedUser.cpf;
			var format_cpf = cpf.substring(0, 3) + ".";
			format_cpf += cpf.substring(3, 6) + ".";
			format_cpf += cpf.substring(6, 9) + "-";
			format_cpf += cpf.substring(9, 14);

			return format_cpf;
		},
		phone() {
			return this.loggedUser.phone;
		},
		email() {
			return this.loggedUser.email;
		},
		birthdate() {
			return this.loggedUser.birthDate;
		},
	},
};
</script>

<style lang="scss" scoped>
$label-padding: 16px;
.parcela {
	color: #0f0f0f;
}

#pay_form {
	display: none;
}

.card-top {
	margin-top: -15px;
}

.input-group {
	position: relative;
	@apply border border-black p-5 my-4;

	&:disabled {
		background-color: darkgrey;
	}
}

label {
	position: absolute;
	top: -14px;
	left: $label-padding;
	background-color: #ffffff;
	@apply text-gray-600 px-1 font-medium;
}

.main {
	background-color: #f7f7f7;
}

.hero {
	background-color: #cce5e4;

	img {
		width: 320px;
	}
}

element.style {
}

#app {
	font-family: Montserrat !important;
	text-align: left !important;
}
.balance {
	text-align: center !important;
}
.btn-outline-primary:hover {
	color: #fff;
	background-color: #25a6d3;
	border-color: #25a6d3;
	border-radius: 1.25rem;
}

.btn-outline-primary {
	color: #25a6d3;
	border-radius: 1.25rem;
}

.btn {
	border-color: #25a6d3 !important;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
	width: 100%;
	padding-right: var(--bs-gutter-x, 0.75rem);
	padding-left: var(--bs-gutter-x, 0.75rem);
	margin-right: auto;
	margin-left: auto;
}

.flex {
	display: flex;
	margin: 0 auto;
}
</style>
