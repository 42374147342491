<template>
    <Loading :active.sync="loading" :is-full-page="true" :opacity="opacity" color="#339AF0" />
</template>

<script>
import Loading from 'vue-loading-overlay'

export default {
    components: { Loading },
    
    props: { 
        loading: {
            type: Boolean,
            default: false
        },
        opacity: {
            type: Number,
            default: 0.5
        }
     }
}
</script>