<template>
    <Page title="MEUS AGENDAMENTOS" backTo="schedule" :loading="loading">

        <div v-if="appointment" class="my-16 text-left">

            <h1 class="font-bold text-gray-500 text-2xl">{{ appointment.venue.name }}</h1>
            <h1 class="font-bold text-gray-500 text-sm">{{ appointment.venue.address }}</h1>
            <h1 class="font-bold text-gray-500 text-sm">{{ appointment.venue.city }} - {{ appointment.venue.state }}</h1>

            <hr class="my-4">

            <h1 class="font-bold text-gray-500 text-2xl my-6">{{ appointment.name }}</h1>
            
            <div class="flex justify-between">
                <div class="font-bold text-gray-500">ESPECIALIDADE</div>
                <div class="font-bold text-gray-500">PARA QUEM</div>
            </div>

            <div class="flex justify-between mt-2">
                <div class="font-bold text-dark">{{ appointment.speciality }}</div>
                <div class="font-bold text-dark">
                    {{ `${appointment.patient}${appointment.isDependent ? ' – Dependente' : ''}` }}
                </div>
            </div>
            
            <div class="flex justify-between mt-4">
                <div class="font-bold text-gray-500">PREFERÊNCIA DE ENDEREÇO</div>
            </div>

            <div class="flex justify-between mt-2">
                <div class="font-bold text-dark">{{ appointment.venue.neighborhood }}</div>
            </div>
            
            <div class="flex justify-between mt-4">
                <div class="font-bold text-gray-500">PREFERÊNCIAS DE DIA E PERÍODO</div>
            </div>

            <div class="flex justify-between mt-2">
                <div class="font-bold text-dark">{{ appointment.dateString2 }}</div>
            </div>

            <div class="status-card mt-6">
                {{ appointment.status.toLocaleLowerCase()}}
            </div>

            <hr class="my-6">

            <a v-if="appointment.isOpen" class="btn btn-blue btn-lg mb-12 mt-10" :href="cancel" target="_blank">CANCELAR SOLICITAÇÃO</a>
        </div>
    </Page>
</template>

<script>
import Page from '../../components/app/Page'
import whatsapp from '../../utils/whatsapp'

export default {
    components: { Page },

    props: [ 'appointment' ],

    data() {
        return {
            loading: false
        }
    },

    computed: {
        cancel() {
            return `${whatsapp.WHATSAPP_URL}&text=Gostaria%20de%20cancelar%20uma%20solicitação`
        }
    }

}
</script>

<style lang="scss" scoped>
.status-card {
    background-color: #E8EFF0;
    padding: 1rem;
    text-align: center;
    border-radius: 2px;
    color: #2F79BD;
    @apply font-bold;
    text-transform: capitalize;
}
</style>