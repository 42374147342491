<template>
  <Page
    back-to="home"
    title="SOLICITAÇÃO PARA AGENDAMENTO DE CONSULTA POR VÍDEO/TELEMEDICINA"
    :steps="3"
    :current-step="1"
    :loading="loading"
  >
    <Header />
    <BackBar :to="{ name: 'home' }" />

    <p class="mt-4 f-text">
      Se precisar, você não fica na mão. Temos Descontos e facilidades de parcelamento para procedimentos cirúrgicos.
    </p>

    <div class="mt-12">
      <button
        class="btn btn-blue btn-lg mb-12"
        type="button"
        v-on:click="callNumber"
      >
        LIGAR
      </button>
    </div>

    <Footer />
  </Page>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "../components/app/Header";
import BackBar from "../components/app/BackBar";
import Footer from "../components/app/Footer";

export default {
  components: { Header, BackBar, Footer },
  mounted() {
    const addressOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Transaction-Key": "njRRmvsxpZyUbU2fUsdbppEaMeNkLLARkNQg44Wks7rbscHNmL",
      },
    };
    fetch(
      "https://api.meutem.com.br/get-client-address/" + this.loggedUser.id,
      addressOptions
    )
      .then((response) => response.json())
      .then((data) => {
        this.neighborhood = data.neighborhood;
        this.state = data.state;
        this.city = data.city;
        this.postal_code = data.postal_code;
      })
      .catch((error) => {
        console.log("error address:" + error);
      });
  },
  methods: {
    concierge() {
      if (!this.message) {
        document.getElementById("msg").focus();
        return false;
      }

      const requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Transaction-Key":
            "njRRmvsxpZyUbU2fUsdbppEaMeNkLLARkNQg44Wks7rbscHNmL",
        },
        body: JSON.stringify({
          card_number: this.cardNumber,
          card_owner: this.userName,
          product_name: this.product,
          onix_code: this.onixCode,
          patient_relationship: "",
          patient_name: this.userName,
          patient_birthdate: "",
          patient_cpf: this.cpf,
          email: this.email,
          telephone_1: this.phone,
          type: "CO",
          specialty: "CLINICA MEDICA",
          exam_group: "",
          uf: this.state,
          city: this.city,
          neighborhoodAgendar: this.neighborhood,
          otherLocations: ["", ""],
          description: this.message,
          status: "200",
          import_file: "",
          cliente_id: this.id,
          loja_id: "140",
        }),
      };
      fetch("https://api.meutem.com.br/agendar/cirurgia", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 200 || data.status == 201) {
            window.location.href = "/surgery.success";
          } else {
            window.location.href = "/surgery.error";
          }
        })
        .catch((error) => {
          console.log("error:" + error);
          window.location.href = "/surgery.error";
        });
    },
    callNumber() {
      window.location.href = "tel:40001640";
    }
  },
  computed: {
    ...mapGetters("account", ["loggedUser"]),

    id() {
      return this.loggedUser.id;
    },
    userName() {
      return this.loggedUser.name;
    },
    cardNumber() {
      return this.loggedUser.cardNumber;
    },
    product() {
      return this.loggedUser.product;
    },
    way() {
      return this.loggedUser.way;
    },
    validator() {
      return this.loggedUser.validator;
    },
    phone() {
      return this.loggedUser.phone;
    },
    onixCode() {
      return this.loggedUser.onixCode;
    },
    cpf() {
      return this.loggedUser.cpf;
    },
    email() {
      return this.loggedUser.email;
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  background-color: #f7f7f7;
}

.hero {
  background-color: #cce5e4;

  img {
    width: 320px;
  }
}
</style>
