/* eslint-disable no-debugger */
<template>
  <div v-if="isCameraVisible" class="overlay-container">
    <div class="camera-container" ref="container">
      <video
        v-show="!isPhotoTaken"
        ref="camera"
        :width="500"
        :height="600"
        autoplay
      ></video>
      <canvas
        v-show="isPhotoTaken"
        id="photoTaken"
        :width="500"
        :height="600"
        ref="canvas"
      ></canvas>
      <div class="capture" @click="takePhoto"></div>
      <div
        v-show="isPhotoTaken"
        class="get-image-button"
        @click="takePhotoData"
      >
        Upload this image
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["isOpen"],
  data() {
    return {
      isCameraOpen: this.isOpen,
      isPhotoTaken: false,
      isLoading: false
    };
  },
  methods: {
    createCameraElment() {
      const constraints = (window.constraits = {
        audio: false,
        video: true
      });

      console.log(navigator);
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.$refs.camera.srcObject = stream;
        })
        .catch(() => {
          alert("Infelizmente seu Browser não suporta este recurso.");
        });
    },
    takePhoto() {
      this.isPhotoTaken = !this.isPhotoTaken;
      const context = this.$refs.canvas.getContext("2d");

      context.drawImage(this.$refs.camera, 120, 0, 400, 480, 0, 0, 500, 600);
    },
    takePhotoData() {
      this.stopCameraStream();
      this.isPhotoTaken = false;
      this.$emit(
        "photoTaken",
        this.$refs.canvas.toDataURL().replace("image/png", "image/octet-stream")
      );
    },
    stopCameraStream() {
      if (!this.$refs.camera) {
        return;
      }

      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach(track => {
        track.stop();
      });
    }
  },
  computed: {
    isCameraVisible() {
      if (this.isOpen) {
        this.createCameraElment();
        return true;
      }
      this.stopCameraStream();
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.overlay-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  display: flex;
}

.camera-container {
  margin: auto;
  min-width: 500px;
  max-width: 500px;
  min-height: 600px;
  max-height: 600px;
  background-color: black;

  div {
    background-color: white;
    border: double 10px #1c1c1c;
    height: 80px;
    width: 80px;
    border-radius: 50px;
    position: absolute;
    bottom: 20px;
    left: calc(50% - 40px);
    cursor: pointer;
    opacity: 0.6;

    &.get-image-button {
      height: 44px;
      width: 210px;
      top: 10px;
      left: calc(50% - 105px);
      color: red;
      font-weight: 900;
    }
  }

  video {
    min-width: 500px;
    max-width: 500px;
    min-height: 600px;
    max-height: 600px;
    object-fit: cover;
  }
  canvas {
    min-width: 500px;
    max-width: 500px;
    min-height: 600px;
    max-height: 600px;
  }
}
</style>
