<template>
    <div>
        <Loading :loading="loading" />
        <AppointmentCard v-for="(appointment, index) in appointments" :key="index" :appointment="appointment" />
    </div>
</template>

<script>
import Loading from '../../app/Loading'
import { mapActions, mapGetters } from 'vuex'
import { formatAppointmentList } from '../../../utils/appointmentFormatter'
import AppointmentCard from './Card'

export default {
    components: { AppointmentCard, Loading },

    props: ['listType'],

    mounted() {

        this.loading = true
        this.listScheduleExam().then(res =>{
            const { finalized, inProgress } = formatAppointmentList(res.data, "Exame", 'exam')

            this.open = [ ...this.open, ...inProgress ]
            this.closed = [ ...this.closed, ...finalized]
            this.loading = false
        }).catch(err => {
            console.error(err)
            this.$toasted.show('Não foi possível buscar seus agendamentos. Tente mais tarde', { position: 'bottom-center', type: 'error', duration: 2500, fullWidth: true,})
        })

        this.listScheduleAppointment().then(res =>{
            const { finalized, inProgress } = formatAppointmentList(res.data, "Consulta", 'appointment')

            this.open = [ ...this.open, ...inProgress ]
            this.closed = [ ...this.closed, ...finalized]

        }).catch(err => {
            console.error(err)
            this.$toasted.show('Não foi possível buscar seus agendamentos. Tente mais tarde', { position: 'bottom-center', type: 'error', duration: 2500, fullWidth: true,})
        })

    },

    methods: {

        ...mapActions('appointments', ['listScheduleExam', 'listScheduleAppointment']),

    },

    data() {
        return {
            loading: false,
            open: [],
            closed: []
        }
    },

    computed: {

        ...mapGetters('account', ['loggedUser']),

        appointments() {
            return this.listType == 0 ? this.open : this.closed
        }
    }

    
}
</script>