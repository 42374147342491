import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import store from '../store'
import routes from './routes'

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

router.beforeEach((to, from, next) => {

    if (to.query.userToken) {

        const token = to.query.userToken
        store.dispatch('account/setUserToken', token)
        next({ name: 'login' })
    }
    else {

        if (!to.matched.some(record => record.meta.allowsUnlogged)) {

            if (!store.getters['account/isLoggedIn']) {
                next({ name: 'login' })
            } else {
                next()
            }
        } else {
            next()
        }
    }

})

export default router