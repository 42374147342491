<template>
    <div class="steps">
        <div v-for="i in steps" :key="i" class="step-item" :class="{active: i == current}">{{i}}</div>
    </div>
</template>

<script>
export default {
    props: ['steps', 'current']
}
</script>

<style scoped>
.steps {
    @apply flex;
}

.step-item {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
    @apply border border-black rounded-full mx-2 font-bold;
}

.step-item.active {
        @apply bg-black text-white;
    }

</style>