import axios from 'axios'
import { AppointmentsURLs } from './api'

export function weekday(prefix) {
    var day = ''
    switch (prefix) {
        case 'mon':
            day = 'Segunda-feira'
            break
        case 'tue':
            day = 'Terça-feira'
            break
        case 'wed':
            day = 'Quarta-feira'
            break
        case 'thu':
            day = 'Quinta-feira'
            break
        case 'fri':
            day = 'Sexta-feira'
            break
        case 'sat':
            day = 'Sábado'
            break
        default:
            break
    }
    return day
}

export function time(english) {
    var time = ''
    switch (english) {
        case 'morning':
            time = 'Manhã'
            break
        case 'afternoon':
            time = 'Tarde'
            break
        case 'evening':
            time = 'Noite'
            break
        default:
            break
    }
    return time
}

export function loadZipcode(zipcode) {
    
    return axios.get(AppointmentsURLs.LOAD_ZIPCODE(zipcode))
}