<template>
	<div>
		<Camera :isOpen="isOpen" @photoTaken="onPhotoTaken" />
		<Page
			back-to="appointment.exams"
			title="SOLICITAÇÃO PARA AGENDAMENTO DE EXAMES E VACINAS"
			:steps="4"
			:current-step="2"
		>
			<h4 class="navigation-subtitle">Anexe pedido médico</h4>

			<h4 class="font-bold text-gray-500 text-left mt-16 text-lg">
				Anexe o arquivo do pedido médico ou tire uma foto:
			</h4>
			<h4 class="font-medium text-gray-500 text-left mb-6 italic text-sm">
				(Os formatos aceitos são: PDF, JPG e PNG)
			</h4>

			<form class="mt-0" v-on:submit="submitForm">
				<div
					class="bg-gray-100 p-2 rounded m-3 flex flex-col mb-10"
					v-if="files.length > 0"
				>
					<div
						class="w-100 rounded m-2 flex items-center"
						v-for="_file in files"
						:key="_file.name"
					>
						<svg
							fill="#999"
							xmlns="http://www.w3.org/2000/svg"
							version="1.1"
							data-icon="file-svg"
							data-container-transform="scale(1 1 ) translate(1 0 )"
							viewBox="0 0 16 16"
							x="0px"
							y="0"
							height="36"
							width="24"
							style="min-width: 24px"
						>
							<path
								d="M0 0v16h13v-11h-5v-5h-8zm9 0v4h4l-4-4z"
								transform="translate(1)"
							/>
						</svg>
						<span class="ml-2 text-xs text-gray-500 text-left">{{
							_file && _file.name
						}}</span>
						<button
							class="m-auto mr-0"
							@click="() => removeFile(_file)"
							type="button"
						>
							<svg
								width="24"
								height="24"
								fill="red"
								xmlns="http://www.w3.org/2000/svg"
								fill-rule="evenodd"
								clip-rule="evenodd"
							>
								<path
									d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 13h-12v-2h12v2z"
								/>
							</svg>
						</button>
					</div>
				</div>

				<button
					class="btn btn-blue btn-lg"
					@click="pickFile"
					type="button"
				>
					{{
						this.files.length > 0
							? "ANEXAR OUTRO ARQUIVO"
							: "ANEXAR ARQUIVO"
					}}
				</button>

				<h4 class="navigation-subtitle my-6 text-gray-500">OU</h4>

				<button
					class="btn btn-blue btn-lg mb-12"
					@click="pickPhoto"
					type="button"
				>
					TIRAR FOTO
				</button>

				<h4 class="font-bold text-dark text-left leading-5 mb-10">
					A foto ou arquivo precisam estar legíveis e serem uma
					solicitação médica.
					<br />
					É imprescindível conter a assinatura do médico com carimbo e
					CRM.
				</h4>

				<button class="btn btn-blue btn-lg mb-12" type="submit">
					PRÓXIMO
				</button>

				<input
					id="fileUpload"
					type="file"
					hidden
					@change="fileUploadChanged"
					accept=".pdf,.jpg,.jpeg,.png"
					multiple="multiple"
				/>
				<input
					id="photoUpload"
					type="file"
					accept="image/*"
					capture
					hidden
					@change="fileUploadChanged"
					multiple="multiple"
				/>
			</form>
		</Page>
	</div>
</template>

<script>
import Camera from "../../../components/media/Camera";
import Page from "../../../components/app/Page";
// import { isMobile } from "../../../utils/mobileUtils"

export default {
	components: { Page, Camera },
	data: () => ({
		files: [],
		isOpen: false,
		filesArray: [],
	}),
	methods: {
		submitForm(e) {
			e.preventDefault();
			if (this.files.length > 0) {
				this.$router.push({ name: "appointment.exams.schedule" });
			} else {
				this.$toasted.show(
					"Anexe pelo menos um arquivo para prosseguir",
					{
						position: "bottom-center",
						type: "error",
						duration: 2500,
						fullWidth: true,
					}
				);
			}
		},
		pickFile() {
			document.getElementById("fileUpload").click();
		},

		pickPhoto() {
			// if(isMobile){
			document.getElementById("photoUpload").click();
			// } else {
			//   this.isOpen = true;
			// }
		},

		onPhotoTaken(photo) {
			this.$store.commit("appointments/updateFile", {
				photo,
			});
			this.files.push(photo);
			this.isOpen = false;
		},

		removeFile(file) {
			this.filesArray = this.filesArray.filter(
				(f) => f.name !== file.name
			);
			this.$store.commit("appointments/updateFile", {
				files: this.filesArray,
			});
			this.files = this.filesArray;
		},

		fileUploadChanged(event) {
			const files = event.target.files;
			if (files && files.length > 0) {
				Array.from(files).forEach((file) => {
					if (
						this.filesArray.findIndex((f) => f.name === file.name) <
						0
					) {
						this.filesArray.push(file);
					}
				});
				this.$store.commit("appointments/updateFile", {
					files: this.filesArray,
				});
				this.files = this.filesArray;
			}
		},
	},
};
</script>
