import { API, AppointmentsURLs } from "../../api"

const state = {
  // Usado para teste - Produto 5142
  // userToken: 'U2FsdGVkX1/Jyb3nyU2AFzSB6/MW1h40LOsoC5JlFxeVn+Vn1jhWV4SEMNOUtWNKexNNYxCxhHHHSWEZzNc87rDV76gLgnaZNHzGTv3el+qw0WlZEvOKNvFvr2015l7rWsGVwPjy0Ky55FO5tAaMDNvOGIEazDMEGGeY0Uv0igLynH23Cl3xJJlLn3gN/pygrDFy9cHLuS75P5DPFSDLlw==',  
  // Usado para teste - Produto 7065
  // userToken: 'U2FsdGVkX184Qzg2OX5UJFk0cquljyWhbbIa9gQ+DHOm5mdDgHvdgleMWlzX7+JfXtRLokk05mIxAX40tvfZ5/+AOEoMro1qsHAUd5+7aLmN9quSvzHvNQImBVA4nQB/xezgjhaMSTO3zHvGg2x/thnjp/B4BXarX+qebIj5DIt5Ht40BoM5Mk6MUwG/z+8Tg6FJhDDuYiPhkeXxQsERqsXVmMW+74lIIycx32xRtXg=',
  onixCode: sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem('user')).onixCode : null,
  userToken: sessionStorage.getItem("usertoken"),
  tokenzeus: sessionStorage.getItem("tokenzeus"),
  loggedUser: sessionStorage.getItem("user")
    ? JSON.parse(sessionStorage.getItem("user"))
    : null,
  extract: [],
  epharmaProducts: ['7136', '7119', '7112', '7120', '7065'] 
}

const mutations = {
  UPDATE_USER_TOKEN(state, userToken) {
    sessionStorage.setItem("usertoken", userToken),
      (state.userToken = userToken)
  },

  DELETE_USER_TOKEN(state) {
    sessionStorage.removeItem("usertoken"), (state.userToken = null)
  },

  setLogged(state, { tokenzeus, loggedUser }) {
    sessionStorage.setItem("tokenzeus", tokenzeus),
      sessionStorage.setItem(
        "user",
        JSON.stringify({ ...state.loggedUser, ...loggedUser })
      ),
      (state.tokenzeus = tokenzeus),
      (state.onixCode = loggedUser.onixCode),

    state.loggedUser = { ...state.loggedUser, ...loggedUser }
  },

  updateExtract(state, extract) {
    state.extract = extract
  },
}

const actions = {
  setUserToken({ commit }, userToken) {
    commit("UPDATE_USER_TOKEN", userToken)
  },

  resetUserToken({ commit }) {
    commit("DELETE_USER_TOKEN")
  },

  getLuckyNumber: ({ rootGetters }) => {
    const token = `Bearer ${rootGetters["account/tokenzeus"]}`
    const user = rootGetters["account/loggedUser"]

    return API.get(AppointmentsURLs.LUCKY_NUMBER(user.cardNumber), {
      headers: {
        Authorization: token,
      },
    })
  },

  // A forma de pegar o codOnix está estranha, a ordem de obtenção dos
  // dados necessários para se realizar o primeiro carregamento da aplicação
  // precisa ser revisto
  loadExtract: ({ rootGetters, commit }) => {
    const token = `Bearer ${rootGetters["account/tokenzeus"]}`
    const postBody = {
      tokenzeus: rootGetters["account/tokenzeus"],
      CodOnix: JSON.parse(sessionStorage.getItem('user')).onixCode,
      Cpf: rootGetters["account/loggedUser"].cpf,
      NumeroCartao: rootGetters["account/loggedUser"].cardNumber
    }

    return API.post(AppointmentsURLs.EXTRACT_URL, postBody, {
      headers: {
        Authorization: token,
      },
    }).then((response) => {
      // console.log(response)
      if (Array.isArray(response.data)) {
        // const activeDependents = response.data.result.filter(
        //   (dep) => dep.DESC_STATUS === "ATIVO"
        // )
        commit("updateExtract", response.data)
      }
    })
  },
}

const getters = {
  isLoggedIn: (state) => {
    return state.tokenzeus != null && state.loggedUser != null
  },

  onixCode: (state) => state.onixCode,

  userToken: (state) => state.userToken,

  tokenzeus: (state) => state.tokenzeus,

  loggedUser: (state) => state.loggedUser,

  extract: (state) => state.extract,

  epharmaProducts: (state) => state.epharmaProducts,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
