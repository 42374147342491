import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

const fieldsIds = {
  name: 36938288,
  neighborhood: 360015027852,
};

export const formatData = (dataUnformatted, title, type, isOpen) => {
  const data = {
    name: title,
    type: "",
    speciality: "Não Informado",
    date: "",
    status: "EM ANDAMENTO",
    isOpen: isOpen,
    patient: dataUnformatted?.fields
      ?.find(({ id }) => id === fieldsIds.name)
      ?.value?.toUpperCase(),
    isDependent: true,
    venue: {
      name: "",
      address: "",
      city: "",
      state: "",
      neighborhood: dataUnformatted?.fields
        ?.find(({ id }) => id === fieldsIds.neighborhood)
        ?.value?.toUpperCase(),
    },
  };

  data.type = enumTypeAppointment[type][3];
  data.date = format(new Date(dataUnformatted.created_at), "dd/MM/yyyy");
  data.dateString = format(new Date(dataUnformatted.created_at), "EEEE bbb", {
    locale: ptBR,
  })
    .toLocaleUpperCase()
    .replace("AM", "MANHÃ")
    .replace("PM", "TARDE");
  data.dateString2 = format(new Date(dataUnformatted.created_at), "EEE - bbb", {
    locale: ptBR,
  })
    .toLocaleUpperCase()
    .replace("AM", "MANHÃ")
    .replace("PM", "TARDE");

  dataUnformatted.tags.forEach((element) => {
    if (element.includes("cidade")) {
      data.venue.city = element
        .slice(7)
        .toUpperCase()
        .replaceAll("_", " ");
    }

    if (element.includes("estado")) {
      data.venue.state = element
        .slice(7)
        .toUpperCase()
        .replace("_", " ");
    }

    if (element.includes("especialidade")) {
      data.speciality = element
        .slice(20)
        .toUpperCase()
        .replace("_", " ");
    }

    if (element.includes("exame") && element.indexOf("exame") === 0) {
      data.status = element
        .slice(6)
        .toUpperCase()
        .replaceAll("_", " ");
    }

    if (element.includes("exame_etapa") && element.indexOf("exame") === 0) {
      data.status = element
        .slice(12)
        .toUpperCase()
        .replaceAll("_", " ");
    }

    if (element.includes("etapa") && element.indexOf("etapa") === 0) {
      data.status = element
        .slice(6)
        .toUpperCase()
        .replaceAll("_", " ");
    }

    if (element.includes("titular")) {
      data.isDependent = false;
    }
  });

  return data;
};

export const formatAppointmentList = (result, title, type = "appointment") => {
  let finalized = [];
  let inProgress = [];

  if (result.results.length > 0) {
    result.results.forEach((element) => {
      if (
        element.tags.includes(enumTypeAppointment[type][0]) ||
        element.tags.includes(enumTypeAppointment[type][1]) ||
        element.tags.includes(enumTypeAppointment[type][2])
      ) {
        finalized.push(formatData(element, title, type, false));
      } else {
        inProgress.push(formatData(element, title, type, true));
      }
    });
  }

  return { finalized, inProgress };
};

const enumTypeAppointment = {
  appointment: [
    "etapa_atendimento_cancelado",
    "etapa_atendimento_concluído",
    "etapa_orçamento_não_aprovado",
    "CONSULTA",
  ],
  exam: [
    "exame_atendimento_cancelado",
    "exame_atendimento_concluído",
    "exame_orçamento_não_aprovado",
    "EXAME",
  ],
};

export const enumEspecialities = [
  { tag: "Cardiologia", name: "CARDIOLOGIA" },
  { tag: "Clínico Geral", name: "CLINICA MEDICA" },
  { tag: "Clínico Geral Pediatrico", name: "CLINICA MEDICA PEDIATRICA" },
  { tag: "Cirurgia Geral", name: "CIRURGIA GERAL" },
  { tag: "Cirurgia Plástica", name: "CIRURGIA GERAL" },
  { tag: "Dermatologia", name: "DERMATOLOGIA" },
  { tag: "Endocrinologia", name: "ENDOCRINOLOGIA" },
  { tag: "Gastroenterologia", name: "GASTROENTEROLOGIA" },
  { tag: "Geriatria", name: "GERIATRIA" },
  { tag: "Ginecologia", name: "GINECOLOGIA" },
  { tag: "Mastologia", name: "MASTOLOGIA" },
  { tag: "Neurologia", name: "NEUROLOGIA" },
  { tag: "Obstetricia", name: "OBSTETRICIA" },
  { tag: "Oftalmologia", name: "OFTALMOLOGIA" },
  { tag: "Ortopedia", name: "ORTOPEDIA" },
  { tag: "Pediatria", name: "PEDIATRIA" },
  { tag: "Pneumologia", name: "PNEUMOLOGIA" },
  { tag: "Psicologia", name: "PSICOLOGIA" },
  { tag: "Psiquiatria", name: "PSIQUIATRIA" },
  { tag: "Reumatologia", name: "REUMATOLOGIA" },
  { tag: "Urologia", name: "UROLOGIA" },
];
