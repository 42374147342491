<template>
    <Page title="ALTERAR DADOS" backTo="home" :loading="loading">
        
        <div class="py-6 mb-10">

            <div class="mb-16"><Input name="name" label="Nome:" placeholder="Nome" v-model="name" disabled/></div>

            <div class="mb-16"><Input name="cpf" label="CPF:" v-mask="'###.###.###-##'" placeholder="000.000.000-00" v-model="cpf" disabled/></div>

            <div class="mb-16"><Input name="birthDate" label="Data Nascimento:" v-mask="'##/##/####'" placeholder="00/00/0000" v-model="birthDate" /></div>

            <div class="mb-16"><Input name="phone" label="Celular:" v-mask="'(##) #####-####'" placeholder="(00) 00000-0000" v-model="phone" /></div>

            <div class="mb-16"><Input name="email" label="E-mail:" v-model="email" /></div>

            <button class="btn btn-blue btn-lg mb-12" @click="save">SALVAR</button>
        </div>
    </Page>
</template>

<script>
import Page from '../../components/app/Page'
import Input from '../../components/ui/form/Input'
import { API, AccountsURLs } from '../../api'
import ServerErrorException from '../../exceptions/ServerErrorException'
import ClientErrorException from '../../exceptions/ClientErrorException'
import { datesMixin } from '../../mixins/dates'

export default {
    components: { Page, Input },
    data() {
        const user = this.$store.getters['account/loggedUser'];
        return {
            loading: false,
            name: user.name,
            cpf: user.cpf,
            birthDate: user.birthDate ? `${user.birthDate.split('-')[2]}/${user.birthDate.split('-')[1]}/${user.birthDate.split('-')[0]}` : '',
            phone: user.phone,
            email: user.email
        }
    },
    mixins: [ datesMixin ],
    methods: {

        save() {
             this.loading = true

            const cnpjPagSeguro = "08561701000101"
            const loggedUser = this.$store.getters['account/loggedUser'];
            const data = {
                "tokenzeus": this.$store.getters['account/tokenzeus'],
                "data_nascimento": this.rawDate(this.birthDate),
                "Telefone": this.phone,
                "cpf": this.cpf,
                "cnpj": cnpjPagSeguro,
                "Nome": this.name,
                "CodOnix": this.$store.getters['account/onixCode'],
                "email": this.email
            }

           API.post(AccountsURLs.UPDATE_ADHESION, data)
            .then(({ data: { clients}}) => {
                this.$notify({
                    group: "success",
                    title: "Sucesso",
                    text: this.successText
                }, 3000);

                this.$store.commit('account/setLogged', {
                    tokenzeus: this.$store.getters['account/tokenzeus'],
                    loggedUser: { ...loggedUser,
                        birthDate: clients[0].birthdate,
                        phone: clients[0].telephone_1,
                    }
                })
                this.$router.push({ name: 'home' })
                // this.$router.push(this.backTo)
            })
            .catch(err => {

                switch (err.constructor) {
                    // Case with blocks - https://eslint.org/docs/rules/no-case-declarations
                    case ServerErrorException: {
                        let msg = err.message + ''

                        if(msg.search('TokenZeus') > -1) {
                            this.$router.push({ name: "login" })
                        }
                        break;
                    }
                    case ClientErrorException: {
                        this.$toasted.show(err.message, { position: 'bottom-center', type: 'error', duration: 3500, fullWidth: true,})
                        break
                    }
                    default: {
                        this.$toasted.show('Não foi possível atualizar a sua conta, tente novamente mais tarde.', { position: 'bottom-center', type: 'error', duration: 2500, fullWidth: true,})
                        console.error(err)
                        break;
                    }
                }
            })
            .finally(() => {
                this.loading = false
            })
        }
    }
}
</script>