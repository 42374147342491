<template>
    <router-link v-on:click.native="needClear" :to="to">
        <div class="app-container flex text-blue border-b border-blue font-bold d-flex align-items-center">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" class="svg-inline--fa fa-chevron-left fa-w-2 w-3" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path></svg>
            <div class="ml-2">VOLTAR</div>
        </div>
    </router-link>
</template>

<script>
export default {
    props: ['to'],

    methods: {
        needClear() {
            if(typeof this.to === 'object' && this.to.name === "home"){
                this.$store.commit('appointments/resetState')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.fa-chevron-left {
    height: 1.5rem;
}
</style>